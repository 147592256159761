import { createContext, useContext, useState } from 'react'
export const session = window.localStorage

export const NavContext = createContext()

export const NavProvider = ({ children }) => {
   const [activeNav, setActiveNav] = useState('Dashboard')

   const handler = (title) => {
      setActiveNav(title)
   }

   return (
      <NavContext.Provider value={{ activeNav, handler }}>
         {children}
      </NavContext.Provider>
   )
}

export function useNavContext() {
   const context = useContext(NavContext)

   if (typeof context === 'undefined') {
      throw new Error(
         'useApiContext should be used within the ApiContext provider!',
      )
   }
   return context
}
