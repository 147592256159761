import CryptoJS from 'crypto-js'
import { createContext, useContext, useEffect, useState } from 'react'

export const session = window.localStorage

//crypting
const encryptUserDetails = (data, secretKey) => {
   const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      secretKey,
   ).toString()
   return encryptedData
}

// Function to decrypt user details
const decryptUserDetails = (encryptedData, secretKey) => {
   const decryptedData = CryptoJS.AES.decrypt(
      encryptedData,
      secretKey,
   ).toString(CryptoJS.enc.Utf8)
   return JSON.parse(decryptedData)
}

const secretKey = 'Kabanga66'

export const SessionContext = createContext()

export const getLoggedUser = () => {
   const user = session.getItem('m_kedjtac_h')
      ? decryptUserDetails(session.getItem('m_kedjtac_h'), secretKey)
      : null
   const token =
      session.getItem('34ukjsgh_s') &&
      session.getItem('34ukjsgh_s') !== 'undefined'
         ? JSON.parse(session.getItem('34ukjsgh_s'))
         : null

   if (user && token) {
      return {
         user: user,
         token: token,
      }
   } else {
      return false
   }
}

export const SessionProvider = ({ children }) => {
   const [loggedUser, setloggedUser] = useState()
   const [resetPassword, setResetPassword] = useState(false)
   const [loggedUserToken, setloggedUserToken] = useState()
   // const [effect, setEffect] = useState()

   // const navigate = useNavigate()

   const startSession = async (user, token = null) => {
      try {
         StoreSession(user)
         token && StoreToken(token)

         await session.setItem(
            'm_kedjtac_h',
            encryptUserDetails(user, secretKey),
         )
         token && (await session.setItem('34ukjsgh_s', JSON.stringify(token)))
      } catch (error) {
         console.error('Error starting session:', error)
         // Handle errors appropriately (e.g., display an error message to the user)
      }
   }

   const StoreSession = (user) => {
      // console.log(user && user?.email)
      if (user && user?.email) setloggedUser(user)
      // : window.location.assign('/login')
   }
   const StoreToken = (token) => {
      token && setloggedUserToken(token)
      // : window.location.assign('/login')
   }

   const destroySession = async () => {
      session.removeItem('m_kedjtac_h')
      session.removeItem('34ukjsgh_s')
      await setloggedUser()
      // window.location.assign('/')
   }

   useEffect(() => {
      !loggedUser && getLoggedUser() && StoreSession(getLoggedUser().user)
      !loggedUserToken && getLoggedUser() && StoreToken(getLoggedUser().token)
   }, [loggedUser, loggedUserToken])

   return (
      <SessionContext.Provider
         value={{
            loggedUser,
            loggedUserToken,
            startSession,
            destroySession,
            resetPassword,
            setResetPassword,
         }}
      >
         {children}
      </SessionContext.Provider>
   )
}

export function useSessionContext() {
   const context = useContext(SessionContext)

   if (typeof context === 'undefined') {
      throw new Error(
         'useSessionContext should be used within the SessionContextProvider!',
      )
   }
   return context
}
