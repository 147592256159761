import { Avatar, DarkThemeToggle, Dropdown, TextInput } from 'flowbite-react'
import { FaUserCircle } from 'react-icons/fa'
import { HiMenu, HiMenuAlt1, HiSearch } from 'react-icons/hi'
import { IoLogOut } from 'react-icons/io5'
import { Link, useNavigate } from 'react-router-dom'
import Logo from '../../pages/auth/login/_components/Logo'
import { useApiContext } from '../../utilis/contexts/ApiContext'
import { usePopUpContext } from '../../utilis/contexts/PopUpContext'
import { useSessionContext } from '../../utilis/contexts/SessionContext'

export default function NavBar({ ToggleSideBar, sideBar }) {
   const { destroySession, loggedUser } = useSessionContext()
   const { refresh } = useApiContext()
   const { openPopUp } = usePopUpContext()
   const navigate = useNavigate()
   const handleLogOut = () => {
      openPopUp({
         title: 'Deconnexion',
         content: 'Voulez-vous vraiment vous deconnecter?',
         decline: {
            title: 'No',
         },
         accept: {
            title: 'Deconnecter',
            handler: () => {
               destroySession().then(() => refresh())
               navigate('/auth')
            },
         },
      })
   }
   return (
      <div className="flex w-full items-center justify-between rounded-lg border p-2 dark:border-none dark:bg-dark-400 md:p-2 md:px-4">
         <div className="hidden gap-2 md:flex">
            {sideBar ? (
               <HiMenuAlt1
                  className="cursor-pointer rounded-md  p-2 text-10 font-bold hover:border-2 hover:bg-slate-100 dark:hover:border-dark dark:hover:bg-secondary"
                  onClick={ToggleSideBar}
               />
            ) : (
               <HiMenu
                  className="cursor-pointer rounded-md  bg-darktext-white p-2 text-10 font-bold dark:bg-secondary dark:hover:border-dark"
                  onClick={ToggleSideBar}
               />
            )}
            <DarkThemeToggle />
         </div>
         <Logo className="md:hidden" size="5" />
         <div className="flex items-center gap-2">
            <Dropdown
               className="text-4 sm:text-6"
               label={
                  <div className="flex items-center gap-2">
                     {loggedUser && (
                        <span className="font-semibold md-max:hidden">
                           {loggedUser.names.split(' ')[0]}
                        </span>
                     )}
                     <Avatar className="rounded-full shadow-xs" rounded />
                  </div>
               }
               inline
               arrowIcon={false}
            >
               <Dropdown.Item className="md:hidden">
                  <div className="flex flex-row items-center gap-2">
                     <DarkThemeToggle
                        content={<span>Mode</span>}
                        className=""
                        title="Theme"
                     />
                     <span>Theme</span>
                  </div>
               </Dropdown.Item>
               <Dropdown.Item>
                  <div
                     onClick={handleLogOut}
                     className="flex flex-row items-center gap-2"
                  >
                     <IoLogOut className="px-2 text-8" />
                     <span>Se Deconnecter</span>
                  </div>
               </Dropdown.Item>
            </Dropdown>
         </div>
      </div>
   )
}
export function AdminNavBar({ ToggleSideBar, sideBar }) {
   const { destroySession, loggedUser } = useSessionContext()
   const { refresh } = useApiContext()
   const { openPopUp } = usePopUpContext()
   const navigate = useNavigate()
   const handleLogOut = () => {
      openPopUp({
         title: 'Deconnexion',
         content: 'Voulez-vous vraiment vous deconnecter?',
         decline: {
            title: 'No',
         },
         accept: {
            title: 'Deconnecter',
            handler: () => {
               destroySession().then(() => refresh())
               navigate('/admin')
               // refresh()
            },
         },
      })
   }
   return (
      <div className="flex w-full items-center justify-between rounded-lg border p-2 dark:border-none dark:bg-dark-400 md:p-2 md:px-4">
         <div className="hidden gap-2 md:flex">
            {sideBar ? (
               <HiMenuAlt1
                  className="cursor-pointer rounded-md  p-2 text-10 font-bold hover:border-2 hover:bg-slate-100 dark:hover:border-dark dark:hover:bg-secondary"
                  onClick={ToggleSideBar}
               />
            ) : (
               <HiMenu
                  className="cursor-pointer rounded-md  bg-darktext-white p-2 text-10 font-bold dark:bg-secondary dark:hover:border-dark"
                  onClick={ToggleSideBar}
               />
            )}
            <DarkThemeToggle />
         </div>
         <Logo className="md:hidden" size="5" />
         <div className="z-10 flex items-center gap-2">
            <Dropdown
               className="text-4 sm:text-6 "
               label={
                  <div className="flex items-center gap-2">
                     {loggedUser && (
                        <span className="font-semibold italic md-max:hidden">
                           Admin, {loggedUser.names.split(' ')[0]}
                        </span>
                     )}
                     <Avatar className="" rounded />
                  </div>
               }
               inline
               arrowIcon={false}
            >
               <Dropdown.Item className="md:hidden">
                  <div className="flex flex-row items-center gap-2">
                     <DarkThemeToggle
                        content={<span>Mode</span>}
                        className=""
                        title="Theme"
                     />
                     <span>Theme</span>
                  </div>
               </Dropdown.Item>
               <Dropdown.Item>
                  <Link
                     to="/admin/account"
                     className="flex flex-row items-center gap-2"
                  >
                     <FaUserCircle className="px-2 text-8" />
                     <span>Profile</span>
                  </Link>
               </Dropdown.Item>
               <Dropdown.Item>
                  <div
                     onClick={handleLogOut}
                     className="flex flex-row items-center gap-2"
                  >
                     <IoLogOut className="px-2 text-8" />
                     <span>Se Deconnecter</span>
                  </div>
               </Dropdown.Item>
            </Dropdown>
            <div className="flex gap-2 md:hidden">
               {sideBar ? (
                  <HiMenuAlt1
                     className="cursor-pointer rounded-md  p-2 text-10 font-bold hover:border-2 hover:bg-slate-100 dark:hover:border-dark dark:hover:bg-secondary"
                     onClick={ToggleSideBar}
                  />
               ) : (
                  <HiMenu
                     className="cursor-pointer rounded-md  bg-darktext-white p-2 text-10 font-bold dark:bg-secondary dark:hover:border-dark"
                     onClick={ToggleSideBar}
                  />
               )}
               {/* <DarkThemeToggle /> */}
            </div>
         </div>
      </div>
   )
}

export function SearchBar({ handdleSearch, title, className }) {
   // const [search, setSearch] = useState([])

   return (
      // <div className=" flex flex-col gap-2 h-240">
      <TextInput
         className={'w-6/12 rounded-full ' + className}
         id="search"
         type="search"
         icon={HiSearch}
         onChange={(e) => handdleSearch(e)}
         // rightIcon={HiMail}
         placeholder={'Rechercher ' + title}
         required
      />
   )
}
