import { createContext, useContext, useEffect, useState } from 'react'

export const CurrencyModalContext = createContext()

export const CurrencyModalProvider = ({ children }) => {
   const [modalState, setModalState] = useState(false)
   const [create, setCreate] = useState(false)
   const [currency, setCurrency] = useState()
   const [viewTicket, setViewTicket] = useState(false)

   const closeView = () => {
      setViewTicket(false)
      setCurrency()
   }
   const openView = () => {
      setViewTicket(true)
      // setTicket()
   }
   const closeModal = () => {
      setModalState(false)
      setCreate()
      setCurrency()
   }

   const openCreate = () => {
      setCreate(true)
      setModalState(true)
   }
   const openModal = (curr) => {
      setCurrency(curr)
      setModalState(true)
   }

   useEffect(() => {
      currency && openView()
   }, [currency])

   return (
      <CurrencyModalContext.Provider
         value={{
            modalState,
            closeModal,
            openModal,
            currency,
            setCurrency,
            viewTicket,
            closeView,
            openCreate,
            create,
         }}
      >
         {children}
      </CurrencyModalContext.Provider>
   )
}

export function useCurencyModalContext() {
   const context = useContext(CurrencyModalContext)

   if (typeof context === 'undefined') {
      throw new Error(
         'useApiContext should be used within the CurrencyModalProvider!',
      )
   }
   return context
}
