import { DarkThemeToggle, Spinner } from 'flowbite-react'
import { FaCarCrash } from 'react-icons/fa'
import { FaServer } from 'react-icons/fa6'
import { HiRefresh } from 'react-icons/hi'
import Logo from '../../pages/auth/login/_components/Logo'
import { useApiContext } from '../../utilis/contexts/ApiContext'

export default function Brand({ error, crashed, setError }) {
   const { refreshAll } = useApiContext()
   return (
      <div className="flex h-[100dvh] w-full  flex-col items-center justify-center gap-2 p-5">
         {/* <Dark */}
         <DarkThemeToggle className="absolute opacity-0" />
         <div className="flex w-full items-center justify-center gap-4">
            <Logo />
         </div>
         {error && (
            <div className="flex max-w-md flex-col items-center gap-2">
               <FaServer className="text-red-700" />
               <span className="text-light text-center dark:text-gray-400">
                  Impossible de vous connecter au server, Veuillez vérifier
                  votre connexion internet puis réessayer.
               </span>
               <HiRefresh
                  onClick={() => {
                     refreshAll()
                     setError && setError()
                  }}
                  className="cursor-pointer text-6"
               />
            </div>
         )}
         {!error && !crashed && (
            <div className="flex items-center gap-2">
               <span className="text-light text-center dark:text-gray-400">
                  Là où les finances prennent vie.
               </span>
            </div>
         )}
         {crashed && (
            <div className="flex max-w-md flex-col items-center gap-2">
               <FaCarCrash className="text-10 text-red-700" />
               <span className="text-light text-center dark:text-gray-400">
                  Oups! Une erreur s'ait produite, l'application a céssée de
                  fonctionner, Veuillez rédemarer.
               </span>
               <HiRefresh
                  onClick={() => window.location.reload()}
                  className="cursor-pointer text-6"
               />
            </div>
         )}
         {error ? '' : !crashed && <Spinner />}
      </div>
   )
}
export function BrandCrashed({ error, crashed, setError }) {
   // const { refreshAll } = useApiContext()
   return (
      <div className="flex h-[100dvh] w-full  flex-col items-center justify-center gap-2 p-5">
         {/* <Dark */}
         <DarkThemeToggle className="absolute opacity-0" />
         <div className="flex w-full items-center justify-center gap-4">
            <Logo />
         </div>
         {error && (
            <div className="flex max-w-md flex-col items-center gap-2">
               <FaServer className="text-red-700" />
               <span className="text-light text-center dark:text-gray-400">
                  Impossible de vous connecter au server, Veuillez vérifier
                  votre connexion internet puis réessayer.
               </span>
               <HiRefresh
                  onClick={() => {
                     setError && setError()
                     // refreshAll()
                  }}
                  className="cursor-pointer text-6"
               />
            </div>
         )}
         {!error && !crashed && (
            <div className="flex items-center gap-2">
               <span className="text-light text-center dark:text-gray-400">
                  Là où les finances prennent vie.
               </span>
            </div>
         )}
         {crashed && (
            <div className="flex max-w-md flex-col items-center gap-2">
               <FaCarCrash className="text-10 text-red-700" />
               <span className="text-light text-center dark:text-gray-400">
                  Oups! Une erreur s'ait produite, l'application a céssée de
                  fonctionner, Veuillez rédemarer.
               </span>
               <HiRefresh
                  onClick={() => window.location.reload()}
                  className="cursor-pointer text-6"
               />
            </div>
         )}
         {error ? '' : !crashed && <Spinner />}
      </div>
   )
}
