import { createContext, useContext, useState } from 'react'

export const PopUpContext = createContext()

export const PopUpProvider = ({ children }) => {
   const [popUpState, setPopUpState] = useState()

   const closePopUp = () => {
      setPopUpState(null)
   }
   const openPopUp = (popup) => {
      setPopUpState(popup)
   }

   return (
      <PopUpContext.Provider value={{ popUpState, closePopUp, openPopUp }}>
         {children}
      </PopUpContext.Provider>
   )
}
export function usePopUpContext() {
   const context = useContext(PopUpContext)

   if (typeof context === 'undefined') {
      throw new Error(
         'usePopUpContext should be used within the PopUpContextProvider!',
      )
   }
   return context
}
