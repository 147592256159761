import { createContext, useContext, useState } from 'react'

export const TransactionsActionContext = createContext()

export const TransactionsActionProvider = ({ children }) => {
   const [activeAction, setActiveAction] = useState('Achats')

   const openAction = (action) => {
      setActiveAction(action)
   }

   return (
      <TransactionsActionContext.Provider value={{ activeAction, openAction }}>
         {children}
      </TransactionsActionContext.Provider>
   )
}
export function useTransactionsActionContext() {
   const context = useContext(TransactionsActionContext)

   if (typeof context === 'undefined') {
      throw new Error(
         'useTransactionsActionContext should be used within the TransactionsActionContextProvider!',
      )
   }
   return context
}
