import { CgArrowsExchangeAlt, CgLogOut } from 'react-icons/cg'
import { FaUser } from 'react-icons/fa6'
import { HiChevronDown, HiChevronUp, HiHome, HiUserAdd } from 'react-icons/hi'
// import { IoSettings } from 'react-icons/io5'
import { useState } from 'react'
import {
   CgArrowBottomLeftR,
   CgArrowsExchangeAltV,
   CgArrowTopRightR,
} from 'react-icons/cg'
import { FaCoins, FaMoneyBill } from 'react-icons/fa'
import { RiHomeFill } from 'react-icons/ri'
import { Link, useNavigate } from 'react-router-dom'
import Logo from '../../pages/auth/login/_components/Logo'
import { useNavContext } from '../../utilis/contexts/NavContext'
import { usePopUpContext } from '../../utilis/contexts/PopUpContext'
import { useSessionContext } from '../../utilis/contexts/SessionContext'

export default function SideBar() {
   const { destroySession } = useSessionContext()
   const { openPopUp } = usePopUpContext()
   const navigate = useNavigate()
   const handleLogOut = () => {
      openPopUp({
         title: 'Deconnexion',
         content: 'Voulez-vous vraiment vous deconnecter?',
         decline: {
            title: 'No',
         },
         accept: {
            title: 'Deconnecter',
            handler: () => {
               destroySession()
               navigate('/auth')
            },
         },
      })
   }
   return (
      <div className=" hidden min-h-screen w-auto min-w-60 flex-col items-center rounded-lg  py-6 pl-4 dark:border-secondary  md:flex md:h-full">
         <Logo size="6" />
         <div className="flex w-full flex-1 flex-col items-center justify-center gap-2">
            <Menu
               Icon={RiHomeFill}
               name={'Tableau de Bord'}
               active={true}
               to={'/'}
            />
            {/* <Menu Icon={FaChartLine} name={'Marché'} /> */}
            {/* <Menu Icon={FaGoogleWallet} /> */}
            <Menu
               Icon={CgArrowsExchangeAlt}
               name={'Transactions'}
               to={'/transactions'}
            />
            <Menu Icon={HiUserAdd} name={'Parrainages'} to={'parrainages'} />
            <Menu to={'account'} Icon={FaUser} name={'Mon Compte'} />
            <Menu
               onClick={handleLogOut}
               Icon={CgLogOut}
               name={'Se Deconnecter'}
            />
            {/* <Menu Icon={IoSettings} name={'Configuration'} /> */}
         </div>
      </div>
   )
}
export function AdminSideBar() {
   const { destroySession } = useSessionContext()
   const { openPopUp } = usePopUpContext()
   const navigate = useNavigate()

   const handleLogOut = () => {
      openPopUp({
         title: 'Deconnexion',
         content: 'Voulez-vous vraiment vous deconnecter?',
         decline: {
            title: 'No',
         },
         accept: {
            title: 'Deconnecter',
            handler: () => {
               destroySession()
               navigate('/admin')
            },
         },
      })
   }
   return (
      <div className=" z-100 flex max-h-screen min-h-screen w-auto min-w-60 flex-col items-center gap-4 overflow-y-auto rounded-lg bg-bg py-6 pl-4 dark:border-secondary dark:bg-dark md:h-full  md-max:fixed md-max:pr-4">
         <Logo size="6" />
         <div className="flex h-full w-full items-center justify-center">
            <div className="flex  w-full flex-1 flex-col items-center justify-center gap-2 overflow-y-auto">
               <Menu
                  Icon={RiHomeFill}
                  name={'Tableau de Bord'}
                  active={true}
                  to={'/admin'}
               />
               {/* <Menu Icon={FaChartLine} name={'Marché'} /> */}
               {/* <Menu Icon={FaGoogleWallet} /> */}
               <Menu Icon={CgArrowsExchangeAlt} name={'Transactions'} dropdown>
                  <SubMenu
                     Icon={CgArrowBottomLeftR}
                     name={'Achats'}
                     // to={'/admin/users'}
                     to={'/admin/transactions'}
                  />
                  <SubMenu
                     Icon={CgArrowTopRightR}
                     name={'Ventes'}
                     to={'/admin/transactions/sellings'}
                  />
                  <SubMenu
                     Icon={CgArrowsExchangeAltV}
                     name={'Echanges'}
                     to={'/admin/transactions/swaps'}
                  />
               </Menu>
               <Menu Icon={FaCoins} name={'Devises'} to={'/admin/devises'} />
               <Menu
                  Icon={FaMoneyBill}
                  name={'Moyens de Paiement'}
                  to={'/admin/methods'}
               />
               <Menu Icon={FaUser} name={'Utilisateurs'} to={'/admin/users'} />
               {/* <Menu Icon={FaNewspaper} name={'Articles'} to={'/admin/users'} /> */}
               {/* <Menu to={'/admin/profile'} Icon={FaUserCircle} name={'Profile'} /> */}
               <Menu
                  onClick={handleLogOut}
                  Icon={CgLogOut}
                  name={'Se Deconnecter'}
               />
               {/* <Menu Icon={IoSettings} name={'Configuration'} /> */}
            </div>
         </div>
      </div>
   )
}

function Menu({ name, to, Icon, onClick, dropdown = false, children }) {
   const { activeNav } = useNavContext()
   const [expand, setExpand] = useState(false)
   const handleExpand = () => {
      setExpand(!expand)
   }
   return (
      <>
         <Link
            to={to && to}
            onClick={() => {
               dropdown && handleExpand()
               onClick && onClick()
            }}
            className={
               activeNav === name
                  ? 'flex w-full cursor-pointer flex-row items-center justify-start gap-4 rounded-lg bg-darktext-white p-4 font-bold text-primary  dark:bg-secondary   dark:text-darktext-white dark:hover:text-bg'
                  : 'flex w-full cursor-pointer flex-row items-center justify-start gap-4 rounded-lg p-4 text-secondary hover:bg-darktext-white  dark:text-darktext   dark:hover:bg-secondary dark:hover:text-bg'
            }
         >
            {Icon ? (
               <Icon className="text-6" />
            ) : (
               <HiHome className="text-6 " />
            )}
            <span className={''}>{name ? name : 'Menu bar'}</span>
            {dropdown && (
               <>
                  {!expand && <HiChevronDown className="text-6" />}
                  {expand && <HiChevronUp className="text-6" />}
               </>
            )}
         </Link>

         {expand && children}
      </>
   )
}
function SubMenu({ name, to, Icon, onClick }) {
   const { activeNav } = useNavContext()
   return (
      <Link
         to={to && to}
         onClick={() => onClick && onClick()}
         className={
            activeNav === name
               ? 'flex w-10/12 cursor-pointer flex-row items-center justify-start gap-4 rounded-lg bg-darktext-white p-2 font-bold text-primary  dark:bg-secondary   dark:text-darktext-white dark:hover:text-bg'
               : 'flex w-10/12 cursor-pointer flex-row items-center justify-start gap-4 rounded-lg p-2 text-secondary hover:bg-darktext-white  dark:text-darktext   dark:hover:bg-secondary dark:hover:text-bg'
         }
      >
         {Icon ? <Icon className="text-6" /> : <HiHome className="text-6 " />}
         <span className={''}>{name ? name : 'Menu bar'}</span>
      </Link>
   )
}
