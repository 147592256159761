import { createContext, useContext, useState } from 'react'

export const ActionContext = createContext()

export const ActionProvider = ({ children }) => {
   const [activeAction, setActiveAction] = useState('Acheter')

   const openAction = (action) => {
      setActiveAction(action)
   }

   return (
      <ActionContext.Provider value={{ activeAction, openAction }}>
         {children}
      </ActionContext.Provider>
   )
}
export function useActionContext() {
   const context = useContext(ActionContext)

   if (typeof context === 'undefined') {
      throw new Error(
         'useActionContext should be used within the ActionContextProvider!',
      )
   }
   return context
}
