import { DarkThemeToggle } from 'flowbite-react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import login1 from '../../../assets/svg/auth1.svg'
import login2 from '../../../assets/svg/auth2.svg'
import Register from '../../../components/Register'
import SignUp from '../../../components/SignUp'
import { usePopUpContext } from '../../../utilis/contexts/PopUpContext'
import { useSessionContext } from '../../../utilis/contexts/SessionContext'
import Logo from './_components/Logo'

export default function Auth({ auth = 'login', admin }) {
   const params = new URLSearchParams(window.location.search)

   const [form, setForm] = useState(auth)
   const [verify, setVerify] = useState()
   const navigate = useNavigate()
   const { startSession } = useSessionContext()
   const { openPopUp } = usePopUpContext()
   const changeForm = (frm) => {
      setForm(frm)
   }
   const handleVerify = (mail) => {
      setVerify(mail)
      setForm('login')
   }
   useEffect(() => {
      if (params.get('promo_code')) {
         changeForm('register')
      }
   }, [])
   return (
      <div className="urbanist min-w-screen  h-100dvh flex max-h-screen flex-row  p-0 sm:p-4 md:p-8 ">
         <div className="flex h-full w-full flex-col items-center justify-center overflow-y-auto rounded-lg  p-2 dark:border-secondary  sm:border sm:p-6 lg:flex-row lg:justify-center lg:rounded-3xl  lg:border-none lg:p-0">
            <div className="justify w-full flex-col items-center justify-center rounded-3xl p-4 dark:border-secondary-500 sm:px-4 lg:flex lg:h-full lg:w-6/12 lg:border lg:p-6">
               <div className="flex w-full items-center justify-between">
                  <Logo />
                  <DarkThemeToggle />
               </div>
               <div className="hidden w-full flex-col items-start justify-start lg:flex">
                  <span className="urbanist font-bold lg:text-8">
                     Bienvenu(e)
                  </span>
                  <span className="urbanist">
                     Là où les finances prennent vie.
                  </span>
               </div>
               <img
                  src={form === 'login' ? login2 : login1}
                  className="hidden h-80 lg:flex"
                  alt=""
                  srcset=""
               />
            </div>
            <div className="flex w-full items-center justify-center lg:w-6/12 lg:px-10">
               {form === 'login' && (
                  <SignUp
                     admin={admin}
                     startSession={startSession}
                     close={() => navigate('/home')}
                     openPopUp={openPopUp}
                     handler={changeForm}
                     verify={verify}
                  />
               )}
               {/* {verify && (
                  <SignUp
                     startSession={startSession}
                     close={() => navigate('/home')}
                     openPopUp={openPopUp}
                     handler={changeForm}
                  />
               )} */}
               {!admin && form === 'register' && (
                  <Register
                     startSession={startSession}
                     close={() => navigate('/home')}
                     openPopUp={openPopUp}
                     handler={changeForm}
                     handleVerify={handleVerify}
                  />
               )}
            </div>
         </div>
      </div>
   )
}
