import { Toast } from 'flowbite-react'
import { useEffect, useState } from 'react'
import { HiServer, HiStatusOffline, HiStatusOnline } from 'react-icons/hi'
import { useFetch } from '../../utilis/hooks'

export default function InternetState() {
   const [showToast, setShowToast] = useState(false)
   const [showServer, setShowServer] = useState(false)
   const [online, setIsOnline] = useState()
   const [server, isLoading, error] = useFetch('/api/connect')
   //    const [isOnline, setIsOnline] = useState(navigator.onLine)

   useEffect(() => {
      const handleOnlineStatusChange = () => {
         setIsOnline(navigator.onLine)
         setShowToast(true)

         navigator.onLine && setTimeout(() => setShowToast(false), 3000)
      }

      // Event listeners for online/offline events
      window.addEventListener('online', handleOnlineStatusChange)
      window.addEventListener('offline', handleOnlineStatusChange)

      // Cleanup: remove event listeners when component is unmounted
      return () => {
         window.removeEventListener('online', handleOnlineStatusChange)
         window.removeEventListener('offline', handleOnlineStatusChange)
      }
   }, [])

   useEffect(() => {
      if (error) {
         setShowServer(true)
         setShowToast(true)
      }
      if (server) {
         setShowServer(false)
         setShowToast(false)
      }
      // console.log(error,server)
   }, [error, server, isLoading])

   return (
      showToast && (
         <div className="fixed left-0 z-990 flex w-screen flex-col items-center justify-center gap-2 p-2 md:top-0 md:p-4 md-max:bottom-10 lg:flex-row">
            {online ? (
               <Online close={setShowToast} />
            ) : (
               <Offline close={setShowToast} />
            )}
            {showServer && !online && <Network />}
         </div>
      )
   )
}

function Online({ close }) {
   return (
      <Toast className=" border-2 border-green-400 dark:bg-dark-400">
         <div className="inline-flex h-6 w-6 shrink-0 items-center justify-center rounded-lg bg-cyan-100 text-cyan-500 dark:bg-cyan-800 dark:text-cyan-200">
            <HiStatusOnline className="h-5 w-5 p-1" />
         </div>

         <div className="ml-3 text-3 font-normal text-green-700 dark:text-green-400 md:text-sm">
            Vous êtes de nouveau connecté à l'internet.
         </div>

         <Toast.Toggle onDismiss={() => close(false)} />
      </Toast>
   )
}
function Offline({ close }) {
   return (
      <Toast className=" border-2 border-red-800 dark:bg-dark-400">
         <div className="inline-flex h-6 w-6 shrink-0 items-center justify-center rounded-lg bg-red-800 text-red-500 dark:text-red-400">
            <HiStatusOffline className="h-5 w-5 p-1" />
         </div>

         <div className="ml-3 text-3 font-normal text-red-600 md:text-sm">
            Vous n'êtes pas connecté à l'internet.
         </div>

         <Toast.Toggle onDismiss={() => close(false)} />
      </Toast>
   )
}
function Network({ close }) {
   return (
      <Toast className=" border-2 border-red-800 dark:bg-dark-400">
         <div className="inline-flex h-6 w-6 shrink-0 items-center justify-center rounded-lg bg-red-800 text-red-500 dark:text-red-400">
            <HiServer className="h-5 w-5 p-1 " />
         </div>

         <div className="ml-3 text-3 font-normal text-red-600 md:text-sm">
            Connexion avec le serveur perdue!
         </div>

         {close && <Toast.Toggle onDismiss={() => close(false)} />}
      </Toast>
   )
}
