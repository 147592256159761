import { Tooltip } from 'flowbite-react'
import { HiTrash } from 'react-icons/hi'
import styled from 'styled-components'

const Cover = styled.div`
   background-image: url(${(props) => props.img});
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
`

const date = new Date()

export default function ImageUpload({
   file,
   handleImagesUpload,
   handleImagesDelete,
   loader,
   className,
   name,
}) {
   // const [file, setFile] = useState('')
   function handleChange(e) {
      // file extention
      const file = e.target.files[0]
      // console.log(file)
      const ext = file.name.split('.')[1]
      const rString = Math.random().toString(36).substring(2, 7)
      const newFileName =
         'img' + date.getTime().toString() + rString + '.' + ext
      // file.name = 150
      console.log(newFileName)

      // setFile(URL.createObjectURL(e.target.files[0]))
      handleImagesUpload({
         link: URL.createObjectURL(e.target.files[0]),
         file: e.target.files[0],
         fileName: newFileName,
      })
   }

   // const remaneFile = (file) => {}
   // useEffect(() => {
   //   file && handleImagesUpload(file)
   // }, [file])

   function handleDelete() {
      // console.log(file)
      handleImagesDelete(file)
      // setFile('')
   }
   return (
      <Cover
         img={file?.link}
         className={
            'flex items-center justify-center  rounded-lg border-2 border-dashed border-slate-DEFAULT-400 hover:bg-opacity-60  ' +
            className
         }
      >
         <div className="flex h-full w-full cursor-pointer flex-col items-center justify-center">
            {!file && (
               <>
                  {/* <HiCamera
                     className="dark:text-darktext"
                     size="large"
                     style={{ height: '2rem' }}
                  ></HiCamera> */}
                  <input
                     value={file}
                     //  hidden
                     name={name && name}
                     accept="image/*"
                     onChange={handleChange}
                     type="file"
                  />
               </>
            )}
            {/* <input hidden accept="image/*" type="file" /> */}
            {file && !loader && (
               <Tooltip style="auto" content="Delete Image">
                  <div
                     onClick={handleDelete}
                     className="flex items-center justify-center rounded-full p-8 hover:bg-slate-200"
                  >
                     <HiTrash className="material-icons-round text-center text-6 text-red-500" />
                  </div>
               </Tooltip>
            )}
            {/* <span className="material-icons-round text-primary text-center">
          add
        </span>
        <span className="text-secondary font-semibold">image</span> */}
         </div>
      </Cover>
   )
}
