import { Button, TextInput } from 'flowbite-react'
import { useEffect, useState } from 'react'
import { FaPassport } from 'react-icons/fa6'
import { HiArrowLeft, HiMail } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import { useApiContext } from '../../utilis/contexts/ApiContext'
import { usePopUpContext } from '../../utilis/contexts/PopUpContext'
import { useSessionContext } from '../../utilis/contexts/SessionContext'
import { countdown } from '../../utilis/functions'
import { usePost } from '../../utilis/hooks'

export default function VerifyEmail({ handler, email }) {
   const [code, setCode] = useState()
   const [min, setMin] = useState()
   const [response, error, isLoadingVerify, setPost] =
      usePost('/auth/verify_code')
   const [newCode, newCodeError, isLoadingNewCode, setPostNewCode] =
      usePost('/auth/verify_email')
   const { startSession } = useSessionContext()
   const { refresh } = useApiContext()
   const { openPopUp, closePopUp } = usePopUpContext()
   const navigate = useNavigate()
   const { resetPassword } = useSessionContext()
   const handleVerify = () => {
      // console.log('post')
      setPost({
         email: email,
         code: code,
      })
   }
   const handleNewCode = () => {
      // console.log('post')
      setPostNewCode({
         email: email,
         // code: code,
      })
   }
   useEffect(() => {
      !min && countdown(5, setMin)
   }, [])

   useEffect(() => {
      if (response?.user) {
         // console.log(response)
         startSession(response.user, response.token).then(() => refresh())
         refresh()
         openPopUp({
            title: 'Verification Email faite avec succès',
            content: `Bienvenue sur MudeCapital, ${response.user.names} ✨ \n ${resetPassword && 'Vous pouvez modifier votre mot de passe dans le menu "Profile". '}`,
            default: {
               title: '👍🏾',
               handler: () => {
                  resetPassword ? navigate('/account') : navigate('/')
                  closePopUp()
               },
            },
         })
      }
      if (error) {
         openPopUp({
            title: 'Erreur de Verification Email',
            content: error.message,
            decline: {
               title: 'Réessayer',
            },
         })
      }
      if (newCode === 'success') {
         // console.log(response)
         // refresh()
         // startSession(response.user, response.token).then(() => refresh())
         openPopUp({
            title: 'Nouveau Code OPT envoyé avec succès',
            content: `Un e-mail contenant votre code de vérification a été envoyé à votre adresse mail ${email}.
                     Si vous ne le trouvez pas dans votre boîte de réception, pensez à vérifier dans vos spams.`,
            default: {
               title: 'Ok',
               handler: () => countdown(5, setMin),
            },
         })
      }
      if (newCodeError) {
         openPopUp({
            title: 'Erreur de Verification Email',
            content: newCodeError.message,
            decline: {
               title: 'Réessayer',
            },
         })
      }
   }, [response, error, newCode, newCodeError])
   return (
      <div className="flex w-full  flex-col items-center justify-center space-y-6 px-4 pb-4">
         {email ? (
            <div className="flex w-full flex-col gap-2 sm:gap-3">
               <label className="">
                  Veuillez saisir le code que nous avons envoyé à votre adresse
                  mail :{' '}
                  <span className="font-semibold underline">{email}</span>
                  {resetPassword && ', pour récupérer votre compte.'}
                  Si vous ne le trouvez pas dans votre boîte de réception,
                  pensez à vérifier dans vos spams.
               </label>
               <TextInput
                  rightIcon={FaPassport}
                  id="code"
                  placeholder="Le Code"
                  onChange={(event) => setCode(event.target.value)}
                  required
               />
            </div>
         ) : (
            <div className="flex w-full flex-col gap-2 sm:gap-3">
               <label className="">Please enter your email :</label>
               <TextInput
                  rightIcon={HiMail}
                  id="email"
                  placeholder="name@company.com"
                  // onChange={}}
                  required
               />
            </div>
         )}
         {/* <div className="flex w-full justify-between">
            <div className="flex items-center gap-2">
               Vous avez pas reçu le Code ?
            </div>
            <span
               // onClick={() => setResetPassword(true)}
               className="cursor-pointer text-sm text-primary hover:underline dark:text-tertiery"
            >
               Demander une nouveau Code (5:00)
            </span>
         </div> */}
         <div className="flex w-full flex-col items-center justify-center gap-2">
            {email ? (
               <Button
                  isProcessing={isLoadingVerify}
                  disabled={!code}
                  className="w-full bg-primary hover:bg-dark"
                  onClick={handleVerify}
               >
                  Verifier le Code
               </Button>
            ) : (
               <Button
                  isProcessing={false}
                  className="w-full bg-primary hover:bg-dark"
               >
                  Verifier mon Email
               </Button>
            )}
            <Button
               onClick={() => handleNewCode()}
               disabled={min}
               isProcessing={isLoadingNewCode}
               color="gray"
               className="flex w-full flex-row items-center gap-4 border text-primary dark:border-secondary dark:text-darktext"
            >
               <span>
                  Demander un nouveau Code dans {min ? '(' + min + ')' : ''}
               </span>
            </Button>
            <span className=" font-semibold text-dark dark:text-darktext">
               ou
            </span>
            <Button
               onClick={() => handler()}
               color="gray"
               className="flex w-full flex-row items-center gap-4 border text-primary dark:border-secondary dark:text-darktext"
            >
               <HiArrowLeft className="mr-2 text-6" />
               <span>Connexion</span>
            </Button>
         </div>
      </div>
   )
}
