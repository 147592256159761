/* eslint-disable no-prototype-builtins */
import axios from 'axios'
import { useEffect, useState } from 'react'
import { apiLink } from '../contexts/ApiContext'
import { getLoggedUser } from '../contexts/SessionContext'

export const updateDataIfNeeded = (
   currentData,
   newData,
   setData,
   setError = null,
) => {
   if (JSON.stringify(currentData) !== JSON.stringify(newData)) {
      // console.log(newData)
      newData && setData(newData)
      setError && setError()
   }
}

export function useFetch(link, auth = null, origin = null) {
   const [data, setData] = useState(null)
   const [forceGet] = useState(false)
   const [isLoading, setIsLoading] = useState(true)
   const [error, setError] = useState()

   const headers = {
      'Content-Type': 'application/json',
      cors: 'no',
      Authorization: auth
         ? auth
         : getLoggedUser()
           ? getLoggedUser().token
           : ' ',
   }

   const fetchData = async (link, headers) => {
      setIsLoading(true)

      try {
         const response = await axios.get(
            origin ? link : apiLink + link,
            !origin && {
               headers,
            },
         )
         setIsLoading(false)
         return response.data
      } catch (err) {
         // console.log(err?.response?.status === 403)
         if (err) {
            if (err?.response) {
               // console.log(err)
               setError({
                  code: err.response.status,
                  message: err.response.data,
               })
            } else {
               setError(err)
            }
         }
         setIsLoading(false)
      }
   }

   const fetchDataAndCompare = async () => {
      setError()
      try {
         const newData = await fetchData(link, headers)
         newData && updateDataIfNeeded(data, newData, setData, setError)
      } catch (error) {
         console.log(error)
      }
   }
   useEffect(() => {
      fetchDataAndCompare()

      // Set up a timer to fetch data periodically (every 5 minutes)
      const intervalId = setInterval(fetchDataAndCompare, 2 * 60 * 1000)

      // Clear the interval when the component is unmounted
      return () => clearInterval(intervalId)
   }, [data, forceGet])

   const refresh = async () => {
      await fetchDataAndCompare()
   }

   return [data, isLoading, error, refresh]
}

export function usePost(link) {
   const [response, setResponse] = useState()
   const [error, setError] = useState()
   const [isLoading, setIsLoading] = useState()
   const [post, setPost] = useState()

   // setLoading(true)
   useEffect(() => {
      if (post) {
         setIsLoading(true)
         const dataToPost = post.array ? post.array : new FormData()

         if (!post.array) {
            for (let index in post) {
               if (post.hasOwnProperty(index)) {
                  let value = post[index]
                  dataToPost.append(index, value)
               }
            }
         }

         const headers = {
            'Content-Type': 'multipart/form-data',
            Authorization: getLoggedUser() ? getLoggedUser().token : ' ',
         }
         // console.log(apiLink + link)
         axios
            .post(apiLink + link, dataToPost, { headers })
            .then((res) => {
               setResponse(res.data)
               setIsLoading(false)
               setPost()
               setError()
            })
            .catch((err) => {
               // console.log(err)
               if (err) {
                  if (err.response) {
                     setError({
                        code: err.response.status,
                        message: err.response.data,
                     })
                  } else {
                     setError({
                        code: err.code,
                        message:
                           err.message + '\r\n Please check your internet. ',
                     })
                  }
               }
               setResponse()
               setPost()
               setIsLoading(false)
            })
      }
      // axios.post
   }, [post])

   return [response, error, isLoading, setPost]
}

// axios
//          .get(link, { headers })
//          .then((res) => {
//             setData(res.data)
//             setIsLoading(false)
//          })
//          .catch((err) => {
//             err &&
//                err.response &&
//                setError({
//                   code: err.response.status,
//                   message: err.response.data,
//                })
//             setIsLoading(false)
//          })

export function objectToFormData(obj) {
   const formData = new FormData()

   for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
         formData.append(key, obj[key])
      }
   }

   return formData
}
