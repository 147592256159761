import {
   Button,
   Label,
   Modal,
   ModalBody,
   Select,
   TextInput,
} from 'flowbite-react'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { BiCoin } from 'react-icons/bi'
import {
   CgArrowBottomLeftR,
   CgArrowsExchangeAlt,
   CgArrowTopLeftR,
} from 'react-icons/cg'
import { FaCoins, FaTrash, FaWallet } from 'react-icons/fa'
import { FaPencil, FaX } from 'react-icons/fa6'
import { HiRefresh } from 'react-icons/hi'
import CurrencyLogo from '../../../components/CurrencyLogo/CurrencyLogo'
import ImageUpload from '../../../components/ImageUpload'
import { assetsLink, useApiContext } from '../../../utilis/contexts/ApiContext'
import { useCurencyModalContext } from '../../../utilis/contexts/CurrencyModalContext'
import { usePopUpContext } from '../../../utilis/contexts/PopUpContext'
import { useSessionContext } from '../../../utilis/contexts/SessionContext'
import { usePost } from '../../../utilis/hooks'
import { InputWallet } from '../../client/_components/Actions/_componenets'

export default function CurrencyModal() {
   const { modalState, closeModal, currency, create } = useCurencyModalContext()
   const { refreshCurrencies } = useApiContext()
   const [edit, setEdit] = useState(false)
   const [response, error, isLoading, setPost] = usePost(
      '/api/currencies/store',
   )
   const [editResponse, editError, isLoadingEdit, setPostEdit] = usePost(
      '/api/currencies/update',
   )
   const [deleteResponse, deleteError, isLoadingDelete, setPostDelte] = usePost(
      '/api/currencies/delete',
   )
   const { openPopUp } = usePopUpContext()
   const [image, setImage] = useState()
   const { destroySession } = useSessionContext()
   const {
      handleSubmit,
      // formState: { errors },
      reset,
      control,
      setValue,
   } = useForm({
      rules: { required: true },
      // defaultValues: { promo_code: params.get('promo_code') },
   })

   useEffect(() => {
      if (edit && currency) {
         setValue('name', currency?.name)
         setValue('short_name', currency?.short_name)
         setValue('wallet', currency?.wallet)
         setValue('purchase_rate', currency?.purchase_rate)
         setValue('selling_rate', currency?.selling_rate)
         setValue('swap_rate', currency?.swap_rate)
         // setValue('symbol', currency?.symbol)
         setImage({ link: assetsLink + currency?.cover })
      }
      // setValue('name', currency?.name)
   }, [edit])

   const handleEdit = () => {
      setEdit(true)
      // setCreate(true)
   }

   useEffect(() => {
      if (response) {
         openPopUp({
            title: '✅ Devise ajouté avec succès',
            content: 'La Devise a été ajouté avec succès',
            accept: {
               title: '👊🏾',
               handler: () => {
                  refreshCurrencies()
                  reset()
                  closeModal()
                  // handleVerify(response?.email)
               },
            },
         })
      }
      if (error) {
         if (error?.code === 403) {
            openPopUp({
               title: 'Session Expirée ⚠️',
               content:
                  "Oops! Votre session a expirée, vous allez devoir vous reconnecter pour continuer l'operation",
               default: {
                  title: 'Ok',
                  handler: destroySession,
               },
            })
         } else {
            openPopUp({
               title: 'Erreur ⚠️',
               content: error.message,
               decline: {
                  title: 'Réessayer',
               },
            })
         }
      }
   }, [response, error])

   //edit
   useEffect(() => {
      if (editResponse) {
         openPopUp({
            title: '✅ Devise modifié avec succès',
            content: 'La Devise a été modifié avec succès',
            accept: {
               title: '👊🏾',
               handler: () => {
                  refreshCurrencies()
                  reset()
                  setEdit()
                  closeModal()
                  // handleVerify(response?.email)
               },
            },
         })
      }
      if (editError) {
         if (editError?.code === 403) {
            openPopUp({
               title: 'Session Expirée ⚠️',
               content:
                  "Oops! Votre session a expirée, vous allez devoir vous reconnecter pour continuer l'operation",
               default: {
                  title: 'Ok',
                  handler: destroySession,
               },
            })
         } else {
            openPopUp({
               title: 'Erreur de Modification',
               content: editError.message,
               decline: {
                  title: 'Réessayer',
               },
            })
         }
      }
   }, [editResponse, editError])
   //delete
   useEffect(() => {
      if (deleteResponse) {
         openPopUp({
            title: '✅ Devise supprimée avec succès',
            content: 'La Devise a été supprimée avec succès',
            accept: {
               title: '👊🏾',
               handler: () => {
                  refreshCurrencies()
                  // reset()
                  // setEdit()
                  closeModal()
                  // handleVerify(response?.email)
               },
            },
         })
      } else {
         if (deleteError) {
            if (deleteError?.code === 403) {
               openPopUp({
                  title: 'Session Expirée ⚠️',
                  content:
                     "Oops! Votre session a expirée, vous allez devoir vous reconnecter pour continuer l'operation",
                  default: {
                     title: 'Ok',
                     handler: destroySession,
                  },
               })
            } else {
               openPopUp({
                  title: 'Erreur de Suppression',
                  content: deleteError.message,
                  decline: {
                     title: 'Réessayer',
                  },
               })
            }
         }
      }
   }, [deleteResponse, deleteError])

   const handlePost = (post) => {
      post.cover = image?.file
      console.log(post)
      setPost(post)
   }
   const handlePostedit = (post) => {
      post.cover = image?.file ? image.file : null
      post.currency_id = currency.id
      !post?.is_crypto && (post.is_crypto = 1)
      // console.log(post)
      setPostEdit(post)
   }
   const handlePostDelete = () => {
      currency &&
         openPopUp({
            title: '✅ Devise supprimée avec succès',
            content: 'La Devise a été supprimée avec succès',
            accept: {
               title: 'Supprimer',
               handler: () => {
                  setPostDelte({
                     currency_id: currency.id,
                  })
                  // handleVerify(response?.email)
               },
            },
            decline: {
               title: 'Non',
            },
         })
   }
   const deleteImage = () => {
      setImage()
   }
   return (
      <Modal
         size="3xl"
         show={modalState}
         onClose={closeModal}
         popup
         // dismissible={true}
         // className="mb-4 pb-4"
      >
         <ModalBody className="rounded-lg p-2 pt-4 dark:bg-secondary sm:p-4 md:p-5">
            <div className="flex w-full flex-col justify-between gap-4 p-4 md-max:mb-14">
               <div className="flex flex-row items-center justify-between">
                  <span className="p-4"></span>
                  <span className="text-5 font-semibold">
                     {edit ? 'Modifier la ' : create && 'Ajouter une'}Devise
                  </span>
                  <FaX
                     onClick={() => {
                        edit ? setEdit(false) : closeModal()
                     }}
                     className="cursor-pointer rounded-full bg-darktext-white p-2 text-8 font-bold text-secondary hover:bg-red-700 dark:bg-dark dark:text-darktext-white"
                  />
               </div>
               {create || edit ? (
                  <form
                     onSubmit={handleSubmit((e) => {
                        edit ? handlePostedit(e) : handlePost(e)
                     })}
                     className="flex flex-col gap-2"
                  >
                     <div className="flex items-center">
                        <ImageUpload
                           handleImagesDelete={deleteImage}
                           file={image}
                           handleImagesUpload={setImage}
                           className="h-24 w-24 md:h-34 md:w-34"
                        />
                     </div>
                     <div className="flex w-full flex-col gap-2 sm:gap-3">
                        <Controller
                           control={control}
                           name="name"
                           render={({ field }) => (
                              <TextInput
                                 rightIcon={FaCoins}
                                 {...field}
                                 // color={errors.first_name && 'failure'}
                                 id="name"
                                 type="text"
                                 placeholder="Nom"
                                 // value={email}
                                 // onChange={(event) => setEmail(event.target.value)}
                                 required
                              />
                           )}
                        />

                        <Controller
                           control={control}
                           name="short_name"
                           render={({ field }) => (
                              <TextInput
                                 rightIcon={BiCoin}
                                 {...field}
                                 // color={errors.first_name && 'failure'}
                                 id="name"
                                 type="text"
                                 placeholder="Symbol"
                                 // value={email}
                                 // onChange={(event) => setEmail(event.target.value)}
                                 required
                              />
                           )}
                        />
                        <Controller
                           control={control}
                           name="wallet"
                           render={({ field }) => (
                              <TextInput
                                 rightIcon={FaWallet}
                                 {...field}
                                 // color={errors.first_name && 'failure'}
                                 id="name"
                                 type="text"
                                 placeholder="Wallet"
                                 // value={email}
                                 // onChange={(event) => setEmail(event.target.value)}
                                 required
                              />
                           )}
                        />
                        <Controller
                           control={control}
                           name="purchase_rate"
                           render={({ field }) => (
                              <TextInput
                                 rightIcon={CgArrowBottomLeftR}
                                 // leftIcon={CgPushChevronLeftR}
                                 {...field}
                                 // color={errors.first_name && 'failure'}
                                 id="name"
                                 // type="number"
                                 placeholder="Taux Achat"
                                 // value={email}
                                 // onChange={(event) => setEmail(event.target.value)}
                                 required
                              />
                           )}
                        />
                        <Controller
                           control={control}
                           name="selling_rate"
                           render={({ field }) => (
                              <TextInput
                                 rightIcon={CgArrowTopLeftR}
                                 {...field}
                                 // color={errors.first_name && 'failure'}
                                 id="name"
                                 // type="text"
                                 placeholder="Taux Vente"
                                 // value={email}
                                 // onChange={(event) => setEmail(event.target.value)}
                                 required
                              />
                           )}
                        />
                        <Controller
                           control={control}
                           name="swap_rate"
                           render={({ field }) => (
                              <TextInput
                                 rightIcon={CgArrowsExchangeAlt}
                                 {...field}
                                 // color={errors.first_name && 'failure'}
                                 id="name"
                                 // type="text"
                                 placeholder="Taux Echange"
                                 // value={email}
                                 // onChange={(event) => setEmail(event.target.value)}
                                 required
                              />
                           )}
                        />
                        <Label>Is Crypto</Label>
                        <Controller
                           control={control}
                           name="is_crypto"
                           render={({ field }) => (
                              <Select {...field} required>
                                 {/* <option disabled selected>
                                    Is Crypto ?
                                 </option> */}
                                 <option value="1">OUI</option>
                                 <option value="0">NON</option>
                              </Select>
                           )}
                        />
                        {create && (
                           <Button
                              // onClick={}
                              type="submit"
                              // disabled={!check}
                              isProcessing={isLoading}
                              className="bg- w-full rounded-xl bg-primary text-6 font-semibold text-white"
                           >
                              Ajouter
                           </Button>
                        )}
                        {edit && (
                           <Button
                              // onClick={}
                              type="submit"
                              // disabled={!check}
                              isProcessing={isLoadingEdit}
                              className="bg- w-full rounded-xl bg-primary text-6 font-semibold text-white"
                           >
                              Modifier
                           </Button>
                        )}

                        {/* <Controller
                           control={control}
                           name="swap_rate"
                           render={({ field }) => (
                              <TextInput
                                 // rightIcon={Hi}
                                 {...field}
                                 // color={errors.first_name && 'failure'}
                                 id="name"
                                 type="file"
                                 // placeholder="Taux Echange"
                                 // value={email}
                                 // onChange={(event) => setEmail(event.target.value)}
                                 required
                              />
                           )}
                        /> */}
                     </div>
                  </form>
               ) : (
                  <>
                     <div className="flex w-full flex-row items-center gap-2">
                        <div className="flex w-full flex-col items-center justify-center gap-2 rounded-xl bg-darktext-white p-4 font-semibold dark:bg-dark-400 md:text-5">
                           <div className="flex w-full flex-row items-center justify-center gap-2 text-5 font-bold md:text-7 ">
                              <span className="text-overflow ">
                                 {currency?.name}
                              </span>
                              <CurrencyLogo img={currency?.cover} />
                           </div>
                        </div>
                        <div className="flex  flex-col items-center justify-center gap-2 rounded-xl bg-darktext-white p-4 font-semibold dark:bg-dark-400 md:text-5">
                           <div className="flex flex-row items-center justify-center  gap-4 text-5 font-bold md:text-7 ">
                              {!isLoadingDelete && (
                                 <FaTrash
                                    onClick={handlePostDelete}
                                    className="cursor-pointer text-6 text-red-400"
                                 />
                              )}
                              {isLoadingDelete && (
                                 <span className="animate-spin">
                                    <HiRefresh className="text-6" />
                                 </span>
                              )}
                              <FaPencil
                                 className="cursor-pointer text-6"
                                 onClick={handleEdit}
                              />
                           </div>
                        </div>
                     </div>
                     <div className="flex w-full flex-col items-center justify-center gap-2 rounded-xl bg-darktext-white font-semibold dark:bg-dark-400 md:text-5">
                        <InputWallet
                           className="border-dark dark:border"
                           copy={true}
                           value={currency?.wallet}
                        />
                     </div>
                     <div className="flex w-full flex-col items-center justify-center gap-2 rounded-xl bg-darktext-white p-4 font-semibold dark:bg-dark-400 md:text-5">
                        <div className="flex w-full flex-row items-center justify-between ">
                           <span>Taux à l'achat :</span>
                           <span>{currency?.purchase_rate} %</span>
                        </div>
                        <div className="flex w-full flex-row items-center justify-between ">
                           <span>Taux à la vente :</span>
                           <span>{currency?.selling_rate} %</span>
                        </div>
                        <div className="flex w-full flex-row items-center justify-between ">
                           <span>Taux à l'échange:</span>
                           <span>{currency?.selling_rate} %</span>
                        </div>
                     </div>
                     <div className="flex w-full flex-col items-center justify-center gap-2 rounded-xl bg-darktext-white p-4 font-semibold dark:bg-dark-400 md:text-5">
                        <div className="flex w-full flex-row   items-center justify-between ">
                           <span className="">Statuts :</span>
                           {currency?.is_crypto ? (
                              <span className="rounded-lg bg-green-700 px-2 py-1">
                                 Crypto
                              </span>
                           ) : (
                              <span className="rounded-lg bg-red-700 px-2 py-1">
                                 Monnaies
                              </span>
                           )}
                        </div>
                     </div>
                  </>
               )}
               {/* {!isLoading ? (
                  currency?.state === 'en attente' && (
                     <div className="flex w-full flex-col gap-4 sm:flex-row">
                        <Button
                           onClick={() => handlePost('Approuvé')}
                           // disabled={!check}
                           // isProcessing={isLoading}
                           className="bg- w-full rounded-xl bg-primary text-6 font-semibold text-white"
                        >
                           Appouver
                        </Button>
                        <Button
                           onClick={() => handlePost('Désapprouvé')}
                           // onClick={() => {
                           //    clear()
                           //    closeModal()
                           // }}
                           color="red"
                           className=" w-full rounded-2xl  text-6 font-semibold"
                        >
                           Désapprouver
                        </Button>
                     </div>
                  )
               ) : (
                  <div className="flex w-full flex-col gap-4 sm:flex-row">
                     <Button
                        // onClick={() => handlePost('Approuvé')}
                        // disabled={!check}
                        isProcessing={isLoading}
                        className="bg- w-full rounded-xl text-6 font-semibold text-white"
                     ></Button>
                  </div>
               )} */}
            </div>
         </ModalBody>
      </Modal>
   )
}
