import { Spinner, Tooltip } from 'flowbite-react'
import { useEffect, useRef, useState } from 'react'
import { HiArrowLeft, HiPlus } from 'react-icons/hi'
import { IoReload } from 'react-icons/io5'
import { usePopUpContext } from '../../utilis/contexts/PopUpContext'
import { useSessionContext } from '../../utilis/contexts/SessionContext'
import { usePost } from '../../utilis/hooks'

export function SponsoredTag({ state, link, id }) {
   const [insternState, setState] = useState(Number(state))
   const [response, error, isLoading, setPost] = usePost(
      `/api/${link}/change_status`,
   )
   const { destroySession } = useSessionContext()

   const { openPopUp } = usePopUpContext()

   const handlePost = (newState) => {
      setPost({
         user_id: id,
         is_active: newState,
      })
      setState(newState)
   }

   useEffect(() => {
      if (response) {
         console.log(response)
      }
      if (error) {
         console.log(error)
         if (error?.code === 403) {
            openPopUp({
               title: 'Session Expirée ⚠️',
               content:
                  "Oops! Votre session a expirée, vous allez devoir vous reconnecter pour continuer l'operation",
               decline: {
                  title: 'Ok',
                  handler: destroySession,
               },
            })
         } else {
            openPopUp({
               title: 'Erreur ⚠️',
               content:
                  'Oops! Une erreur est survenue, veuillez verifier votre connexion internet, puis reessayer!',
               decline: {
                  title: 'Reessayer',
                  // handler: clear,
               },
            })
            setState(Number(state))
         }
      }
   }, [response, error])

   return (
      // <div className="p-2 rounded-md">
      isLoading ? (
         <Spinner />
      ) : insternState === 1 ? (
         <span
            onClick={() => handlePost(0)}
            className="cursor-pointer rounded-md bg-green-500 p-2 text-slate-100"
         >
            Active
         </span>
      ) : (
         <span
            onClick={() => handlePost(1)}
            className="cursor-pointer rounded-md bg-red-500 p-2 text-slate-100"
         >
            Desactivate
         </span>
      )
      // </div>
   )
}
export function AdminTag({ state, link, id }) {
   const [insternState, setState] = useState(Number(state))
   const [response, error, isLoading, setPost] = usePost(
      `/api/${link}/make_admin`,
   )
   const { destroySession } = useSessionContext()

   const { openPopUp } = usePopUpContext()

   const handlePost = (newState) => {
      openPopUp({
         title: 'Make User Admin',
         content:
            insternState === 1
               ? 'Voulez-vous vraiment faire de cet utilisateur un Administrateur ?'
               : 'Voulez-vous vraiment supprimer cet utilisateur de la liste de Administrateur ?',
         decline: {
            title: 'Non',
         },
         accept: {
            title: 'Oui',
            handler: () => {
               setPost({
                  user_id: id,
                  is_active: newState,
               })
               setState(newState)
            },
         },
      })
   }

   useEffect(() => {
      if (response) {
         console.log(response)
      }
      if (error) {
         console.log(error)
         if (error?.code === 403) {
            openPopUp({
               title: 'Session Expirée ⚠️',
               content:
                  "Oops! Votre session a expirée, vous allez devoir vous reconnecter pour continuer l'operation",
               decline: {
                  title: 'Ok',
                  handler: destroySession,
               },
            })
         } else {
            openPopUp({
               title: 'Erreur ⚠️',
               content:
                  'Oops! Une erreur est survenue, veuillez verifier votre connexion internet, puis reessayer!',
               decline: {
                  title: 'Reessayer',
                  // handler: clear,
               },
            })
            setState(Number(state))
         }
      }
   }, [response, error])

   return (
      // <div className="p-2 rounded-md">
      isLoading ? (
         <Spinner />
      ) : insternState === 1 ? (
         <span
            onClick={() => handlePost(0)}
            className="cursor-pointer rounded-md bg-green-500 p-2 text-slate-100"
         >
            Admin
         </span>
      ) : (
         <span
            onClick={() => handlePost(1)}
            className="cursor-pointer rounded-md bg-red-500 p-2 text-slate-100"
         >
            Simple
         </span>
      )
      // </div>
   )
}
export function ActiveTag({
   state,
   link,
   id,
   openPopUp,
   active = 'Active',
   not = 'Not Active',
}) {
   return Number(state) === 1 ? (
      <span className="cursor-pointer rounded-md bg-green-500 p-2 text-slate-100">
         {active ? active : 'Oui'}
      </span>
   ) : Number(state) === 0 ? (
      <span className="cursor-pointer rounded-md bg-red-500  p-2 text-slate-100">
         {not ? not : 'Non'}
      </span>
   ) : (
      <span className="cursor-pointer rounded-md bg-gray-600 p-2 text-slate-100">
         {state}
      </span>
   )
}
export function StateTag({ state, link, id, openPopUp }) {
   return state === 'Approuvé' ? (
      <span className="cursor-pointer rounded-md bg-green-500 p-2 text-slate-100">
         {state}
      </span>
   ) : state === 'en attente' ? (
      <span className="cursor-pointer rounded-md bg-gray-600 p-2 text-slate-100">
         {state}
      </span>
   ) : (
      <span className="cursor-pointer rounded-md bg-red-500 p-2 text-slate-100">
         {state}
      </span>
   )
}

export function BtnCreate({ onClick, title, noTitle = false }) {
   return (
      <Tooltip content={title && title} style="auto">
         <div
            //  onClick={() => console.log(11)}
            onClick={() => onClick()}
            className="flex cursor-pointer items-center rounded-lg bg-green-500 p-2  text-slate-200"
         >
            <HiPlus />
            <span className="hidden md:inline">
               {!noTitle && (title ? title : 'Create')}
            </span>
         </div>
      </Tooltip>
   )
}
export function BtnRefresh({ onClick }) {
   const ref = useRef(null)

   // function spin() {
   //    ref.current.classList.add('animate-spin')

   //    // console.log(112)
   //    const intervalId = setTimeout(() => {
   //       // console.log(111)
   //       ref.current.classList.remove('animate-spin')
   //    }, 1 * 1000)
   //    // intervalId
   //    clearTimeout(intervalId)
   // }

   return (
      <Tooltip content="Refresh" style="auto">
         <div
            //  onClick={() => console.log(11)}
            onClick={() => {
               onClick()
               // spin()
            }}
            className="flex cursor-pointer items-center rounded-lg bg-gray-700 p-2 text-slate-200"
         >
            <span className="" ref={ref}>
               <IoReload />
            </span>
            {/* <span className="material-icons-round  cursor-pointer " ref={ref}>
               refresh
            </span>{' '} */}
            {/* Create */}
         </div>
      </Tooltip>
   )
}

export function BtnBack() {
   return (
      <Tooltip content="Back" style="auto">
         <div
            //  onClick={() => console.log(11)}
            onClick={() => {
               window.history.back()
            }}
            className="flex cursor-pointer items-center rounded-lg bg-gray-700 p-2  text-slate-200"
         >
            <span className="material-icons-round  cursor-pointer ">
               <HiArrowLeft />
            </span>{' '}
            {/* Create */}
         </div>
      </Tooltip>
   )
}
// export function BtnScan() {
//    return (
//       <ScanModalContext.Consumer>
//          {({ openModal }) => (
//             <div
//                //  onClick={() => console.log(11)}
//                onClick={() => {
//                   openModal()
//                }}
//                className="flex cursor-pointer items-center rounded-lg bg-gray-700 p-2  text-slate-200"
//             >
//                <span className="material-icons-round  cursor-pointer ">
//                   <HiOutlineQrcode />
//                </span>{' '}
//                {/* Create */}
//             </div>
//          )}
//       </ScanModalContext.Consumer>
//    )
// }
