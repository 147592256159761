import { Button, Modal, ModalBody } from 'flowbite-react'
import { useEffect } from 'react'
import { FaX } from 'react-icons/fa6'
import CurrencyLogo, {
   MethodLogo,
} from '../../../../components/CurrencyLogo/CurrencyLogo'
import { usePopUpContext } from '../../../../utilis/contexts/PopUpContext'
import { useSellingContext } from '../../../../utilis/contexts/SellingContext'
import { useSessionContext } from '../../../../utilis/contexts/SessionContext'
import { usePost } from '../../../../utilis/hooks'
import { InputWallet } from '../../../client/_components/Actions/_componenets'

export default function SellingModal({ refresh }) {
   const { closeShowModal, showSellingModalState, show, clear } =
      useSellingContext()
   const { destroySession } = useSessionContext()
   const [response, error, isLoading, setPost] = usePost(
      '/api/sellings/change_state',
   )
   const { openPopUp } = usePopUpContext()

   useEffect(() => {
      if (response) {
         if (response.user_id) {
            openPopUp({
               title: 'Approbation Vente ✅',
               content: 'Vente ' + response.state + '  avec succès! ',
               default: {
                  title: 'Ok',
                  handler: () => {
                     refresh()
                     clear()
                     closeShowModal()
                  },
               },
            })
         } else {
            openPopUp({
               title: 'Approbation  Vente ⚠️',
               content:
                  'Oops! Une erreur est survenue, veuillez verifier votre connexion internet, puis reessayer!',
               decline: {
                  title: 'Reessayer',
                  // handler: clear,
               },
            })
         }
      } else {
         if (error) {
            console.log(error)
            if (error?.code === 403) {
               openPopUp({
                  title: 'Session Expirée ⚠️',
                  content:
                     "Oops! Votre session a expirée, vous allez devoir vous reconnecter pour continuer l'operation",
                  decline: {
                     title: 'Ok',
                     handler: destroySession,
                  },
               })
            } else {
               openPopUp({
                  title: 'Approbation  Vente ⚠️',
                  content:
                     'Oops! Une erreur est survenue, veuillez verifier votre connexion internet, puis reessayer!',
                  decline: {
                     title: 'Reessayer',
                     // handler: clear,
                  },
               })
            }
         }
      }
   }, [response, error, isLoading])

   const handlePost = (state) => {
      setPost({
         selling_id: show.id,
         user_id: show.user_id,
         state: state,
      })
   }
   return (
      // show?.amount && (
      <Modal
         size="3xl"
         show={showSellingModalState}
         onClose={closeShowModal}
         popup
         dismissible={true}
         className="h-screen p-0"
      >
         <ModalBody className="rounded-lg p-4 dark:bg-secondary md:p-5">
            <div className="flex h-full w-full flex-col justify-between gap-4">
               <div className="flex flex-row items-center justify-between">
                  <span className="p-4"></span>
                  <span className="text-5 font-semibold">Vente de</span>
                  <FaX
                     onClick={() => closeShowModal()}
                     className="cursor-pointer rounded-full bg-darktext-white p-2 text-8 font-bold text-secondary hover:bg-red-700 dark:bg-dark dark:text-darktext-white"
                  />
               </div>
               <div className="flex w-full flex-col items-center justify-center gap-2 rounded-xl bg-darktext-white p-4 font-semibold dark:bg-dark-400 md:text-5">
                  <div className="flex w-full flex-row items-center justify-center gap-2 text-5 font-bold md:text-7 ">
                     <span className="text-overflow ">
                        {Number(show?.amount) / Number(show?.price)}
                     </span>
                     <CurrencyLogo img={show?.currency?.cover} />
                     {/* <show.currency.avatar /> */}
                  </div>
               </div>

               <div className="flex w-full flex-col items-center justify-center gap-2 rounded-xl bg-darktext-white p-4 font-semibold dark:bg-dark-400 md:text-5">
                  <div className="flex w-full flex-row items-center justify-between ">
                     <span>Montant en USD :</span>
                     <span>{show?.amount} $</span>
                  </div>
                  <div className="flex w-full flex-row items-center justify-between ">
                     <span>Frais :</span>
                     <span>{show?.rate} $</span>
                  </div>
                  <div className="flex w-full flex-row items-center justify-between ">
                     <span>Montant Total à Recevoir :</span>
                     <span>
                        {(
                           parseFloat(show?.amount) - parseFloat(show?.rate)
                        ).toFixed(2)}{' '}
                        $
                     </span>
                  </div>
               </div>
               <div className="flex w-full flex-col items-center justify-center gap-2 rounded-xl bg-darktext-white p-4 font-semibold dark:bg-dark-400 md:text-5">
                  <div className="flex w-full flex-row items-center justify-between ">
                     <span>Moyen de Paiement :</span>
                     <div className="flex items-center gap-1 ">
                        <MethodLogo img={show?.payment_method?.cover} />
                        {show?.method?.name}
                     </div>
                  </div>
                  <div className="flex w-full flex-col items-center justify-center gap-2 rounded-xl bg-darktext-white font-semibold dark:bg-dark-400 md:text-5">
                     <InputWallet
                        className="border-dark dark:border"
                        copy={true}
                        value={show?.selling_wallet}
                     />
                  </div>
                  <div className="flex w-full flex-row   items-center justify-between ">
                     <span className="">Reference :</span>
                     <span className="">{show?.reference}</span>
                  </div>
               </div>
               <div className="flex w-full flex-col items-center justify-center gap-2 rounded-xl bg-darktext-white p-4 font-semibold dark:bg-dark-400 md:text-5">
                  <div className="flex w-full flex-row items-center justify-between ">
                     <span>Effectué par :</span>
                     <div className="flex items-center gap-1 ">
                        {show?.user?.names}
                     </div>
                  </div>
                  <div className="flex w-full flex-row   items-center justify-between ">
                     <span className=""> Email :</span>
                     <span className="">{show?.user?.email}</span>
                  </div>
                  <div className="flex w-full flex-row   items-center justify-between ">
                     <span className="">Telelphone :</span>
                     <span className="">{show?.user?.telephone}</span>
                  </div>
               </div>
               <div className="flex w-full flex-col items-center justify-center gap-2 rounded-xl bg-darktext-white p-4 font-semibold dark:bg-dark-400 md:text-5">
                  <div className="flex w-full flex-row items-center justify-between ">
                     <span>Statut:</span>
                     <span>{show?.state}</span>
                  </div>
               </div>
               {!isLoading ? (
                  show?.state === 'en attente' && (
                     <div className="flex w-full flex-col gap-4 sm:flex-row">
                        <Button
                           onClick={() => handlePost('Approuvé')}
                           // disabled={!check}
                           // isProcessing={isLoading}
                           className="bg- w-full rounded-xl bg-primary text-6 font-semibold text-white"
                        >
                           Appouver
                        </Button>
                        <Button
                           onClick={() => handlePost('Désapprouvé')}
                           // onClick={() => {
                           //    clear()
                           //    closeModal()
                           // }}
                           color="red"
                           className=" w-full rounded-2xl  text-6 font-semibold"
                        >
                           Désapprouver
                        </Button>
                     </div>
                  )
               ) : (
                  <div className="flex w-full flex-col gap-4 sm:flex-row">
                     <Button
                        // onClick={() => handlePost('Approuvé')}
                        // disabled={!check}
                        isProcessing={isLoading}
                        className="bg- w-full rounded-xl text-6 font-semibold text-white"
                     ></Button>
                  </div>
               )}
            </div>
         </ModalBody>
      </Modal>
      // )
   )
}
