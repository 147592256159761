import { Button, TextInput } from 'flowbite-react'
import { useEffect, useState } from 'react'
import { HiArrowLeft, HiMail } from 'react-icons/hi'
import { usePopUpContext } from '../../utilis/contexts/PopUpContext'
import { useSessionContext } from '../../utilis/contexts/SessionContext'
import { usePost } from '../../utilis/hooks'

export default function ResetPassword({ handler, handleVerify }) {
   const [email, setEmail] = useState()
   const [response, error, isLoadingVerify, setPost] =
      usePost('/auth/verify_email')
   const { openPopUp } = usePopUpContext()
   const { setResetPassword } = useSessionContext()

   const handlePost = () => {
      // console.log(email)
      email &&
         setPost({
            email: email,
         })
   }
   useEffect(() => {
      if (response) {
         openPopUp({
            title: 'Verification Email faite avec succès',
            content: `Un e-mail contenant votre code de vérification a été envoyé à votre adresse mail ${email}.
                     Si vous ne le trouvez pas dans votre boîte de réception, pensez à vérifier dans vos spams.`,
            default: {
               title: '👍🏾',
               handler: () => {
                  setResetPassword(true)
                  handleVerify(email)
                  // handler()
                  // closePopUp()
               },
            },
         })
      }
      if (error) {
         openPopUp({
            title: 'Erreur de Verification Email',
            content: error.message,
            decline: {
               title: 'Réessayer',
            },
         })
      }
   }, [response, error])
   return (
      <div className="flex w-full  flex-col items-center justify-center space-y-6 px-4 pb-4">
         <div className="flex w-full flex-col gap-2 sm:gap-3">
            <label className="">Veuillez saisir votre adresse mail :</label>
            <TextInput
               rightIcon={HiMail}
               id="email"
               placeholder="name@company.com"
               onChange={(e) => setEmail(e.target.value)}
               required
            />
         </div>

         <div className="flex w-full flex-col items-center justify-center gap-2">
            <Button
               isProcessing={isLoadingVerify}
               className="w-full bg-primary hover:bg-dark"
               onClick={() => handlePost()}
            >
               Verifier mon Email
            </Button>

            <Button
               onClick={() => handler()}
               color="gray"
               className="flex w-full flex-row items-center gap-4 border text-primary dark:border-secondary dark:text-darktext"
            >
               <HiArrowLeft className="mr-2 text-6" />
               <span>Connexion</span>
            </Button>
         </div>
      </div>
   )
}
