import { Spinner, Table } from 'flowbite-react'
import { useEffect, useState } from 'react'
import CurrencyLogo from '../../../components/CurrencyLogo/CurrencyLogo'
import {
   ActiveTag,
   BtnBack,
   BtnCreate,
   BtnRefresh,
} from '../../../components/EventEssentiels'
import { SearchBar } from '../../../components/NavBar'
import { useApiContext } from '../../../utilis/contexts/ApiContext'
import { useCurencyModalContext } from '../../../utilis/contexts/CurrencyModalContext'
import { useNavContext } from '../../../utilis/contexts/NavContext'
import { updateDataIfNeeded } from '../../../utilis/hooks'
import CurrencyModal from './CurrencyModal'
// import PurchaseModal from './CurrencyModal'

export default function Currencies() {
   const { handler } = useNavContext()
   const { openModal, openCreate } = useCurencyModalContext()
   const [localCurrencies, setLocalCurrencies] = useState([])
   const {
      currencies,
      refreshCurrencies,
      errorCurrencies,
      isLoadingCurrencies,
   } = useApiContext()

   const handdleSearch = (e) => {
      const word = e.target.value

      word.length > 2
         ? setLocalCurrencies(
              currencies.filter(({ name, short_name }) => {
                 return (
                    name.toLowerCase().includes(word.toLowerCase()) ||
                    short_name?.toLowerCase().includes(word.toLowerCase())
                 )
              }),
           )
         : setLocalCurrencies(currencies)
   }
   useEffect(() => {
      // refreshCurrencies()
      handler('Devises')
      // currencies && setLocalCurrencies(currencies)
      // refresh
   }, [])

   useEffect(() => {
      updateDataIfNeeded(localCurrencies, currencies, setLocalCurrencies)
      // userError && console.log(userError)
   }, [currencies, errorCurrencies])
   return (
      <div className="fex-1 flex max-h-full w-full flex-col gap-4 py-2 pr-2">
         <div className="justify- flex flex-col-reverse items-center gap-4 rounded bg-darktext-white p-2  dark:bg-dark-400 md:flex-row">
            <div className="flex flex-row items-center gap-2">
               <BtnBack />
               <BtnCreate title={'Devise'} onClick={openCreate} />
               <BtnRefresh onClick={() => refreshCurrencies()} />
            </div>
            <div className="flex w-full flex-row items-center gap-2">
               <span className="p-2 font-semibold lg:text-6">Devices</span>
               <SearchBar
                  title={'une Devise'}
                  handdleSearch={handdleSearch}
                  className="flex-1"
               />
            </div>
         </div>
         <div className="overflow-x-scroll">
            <Table>
               <Table.Head>
                  {/* <Table.HeadCell>Event Name</Table.HeadCell> */}
                  <Table.HeadCell>Nom</Table.HeadCell>
                  <Table.HeadCell>Symbol</Table.HeadCell>
                  <Table.HeadCell>Taux à l'achant</Table.HeadCell>
                  <Table.HeadCell>Taux à la vente</Table.HeadCell>
                  <Table.HeadCell>Taux à l'échange</Table.HeadCell>
                  <Table.HeadCell>Crypto</Table.HeadCell>
                  {/* <Table.HeadCell>Sponsored</Table.HeadCell> */}
               </Table.Head>
               <Table.Body className="divide-y">
                  {isLoadingCurrencies && <TheSpiner />}
                  {currencies &&
                  localCurrencies &&
                  Array.isArray(localCurrencies) &&
                  localCurrencies?.length > 0 ? (
                     localCurrencies?.map((currency) => (
                        <Table.Row
                           className=" border-b p-2 dark:border-secondary-500 dark:bg-dark-400"
                           key={currency?.id + 'currency_admin'}
                        >
                           <Table.Cell
                              onClick={() => {
                                 openModal(currency)
                              }}
                              className=" cursor-pointer  hover:bg-darktext-white hover:font-bold dark:hover:bg-dark"
                           >
                              <span className="flex items-center gap-2">
                                 <CurrencyLogo img={currency?.cover} />
                                 {currency?.name}
                              </span>
                           </Table.Cell>
                           <Table.Cell
                              onClick={() => {
                                 openModal(currency)
                              }}
                              className="flex cursor-pointer items-center gap-2 hover:bg-darktext-white  hover:font-bold  dark:hover:bg-dark"
                              // className=""
                           >
                              {currency?.short_name}
                           </Table.Cell>
                           <Table.Cell>{currency?.purchase_rate}%</Table.Cell>
                           <Table.Cell>{currency?.selling_rate}%</Table.Cell>
                           <Table.Cell>{currency?.swap_rate}%</Table.Cell>

                           <Table.Cell>
                              <ActiveTag
                                 active="Oui"
                                 not="Non"
                                 state={currency?.is_crypto}
                              />
                           </Table.Cell>
                        </Table.Row>
                     ))
                  ) : (
                     <Table.Row
                        className="dark:bg-dark-400 "
                        // key={event.id + 'events'}
                     >
                        <Table.Cell
                           colSpan={6}
                           // onClick={() => changeEvent(event)}
                           className="cursor-pointer whitespace-nowrap text-center font-medium text-gray-900 dark:text-red-500"
                        >
                           No Data to Display
                        </Table.Cell>
                     </Table.Row>
                  )}
               </Table.Body>
            </Table>
         </div>
         <CurrencyModal />
      </div>
   )
}

export function TheSpiner() {
   return (
      <Table.Row className=" dark:bg-dark-400 ">
         <Table.Cell colSpan={5} className="m-auto">
            <div className="flex w-full   items-center justify-center">
               <Spinner className="mr-6/12" />
            </div>
         </Table.Cell>
      </Table.Row>
   )
}
