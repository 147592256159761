import { createContext, useContext, useState } from 'react'

export const PurchaseContext = createContext()

export const PurchaseProvider = ({ children }) => {
   const [purchaseModalState, setPurchaseModalState] = useState(false)
   const [show, setShow] = useState()
   const [showPurchaseModalState, setShowPurchaseModalState] = useState(false)

   const [purchase, setPurchase] = useState({
      currency: undefined,
      amount: undefined,
      price: undefined,
      wallet: undefined,
      fees: undefined,
      total: undefined,
      method: undefined,
   })

   const addCurrency = (currency) => {
      // console.log(currency)
      setPurchase({
         currency: currency,
         amount: purchase?.amount,
         price: currency?.price,
         wallet: purchase?.wallet,
         fees:
            purchase?.amount &&
            (purchase.amount * currency.purchase_rate).toFixed(2),
         total:
            purchase?.currency &&
            (
               parseFloat(purchase?.amount) +
               parseFloat(purchase?.amount) *
                  parseFloat(currency?.purchase_rate)
            ).toFixed(2),
         method: purchase?.method,
      })
   }
   const addAmount = (amount) => {
      purchase?.currency &&
         setPurchase({
            currency: purchase?.currency,
            wallet: purchase?.wallet,
            price: purchase?.price,
            amount: amount,
            fees:
               purchase?.currency &&
               (amount * purchase?.currency?.purchase_rate).toFixed(2),
            // total: parseInt(amount) + 3,
            total:
               purchase?.currency &&
               (
                  parseFloat(amount) +
                  parseFloat(amount) *
                     parseFloat(purchase.currency.purchase_rate)
               ).toFixed(2),
            method: purchase?.method,
         })
   }
   const addWallet = (wallet) => {
      setPurchase({
         currency: purchase?.currency,
         amount: purchase?.amount,
         price: purchase?.price,
         wallet: wallet,
         fees: purchase?.fees,
         total: purchase?.total,
         method: purchase?.method,
      })
   }
   const addMethod = (method) => {
      setPurchase({
         currency: purchase?.currency,
         amount: purchase?.amount,
         price: purchase?.price,
         wallet: purchase?.wallet,
         fees: purchase?.fees,
         total: purchase?.total,
         method: method,
      })
   }
   const clear = (method) => {
      setPurchase({
         currency: purchase?.currency,
         price: purchase?.price,
         amount: undefined,
         wallet: undefined,
         fees: undefined,
         total: undefined,
         method: method,
      })
   }

   const openModal = () => {
      setPurchaseModalState(true)
   }

   const closeModal = () => {
      setPurchaseModalState(false)
   }
   const openShowModal = (purchase) => {
      setShow(purchase)
      setShowPurchaseModalState(true)
   }

   const closeShowModal = () => {
      setShow()
      setShowPurchaseModalState(false)
   }

   return (
      <PurchaseContext.Provider
         value={{
            show,
            purchase,
            addAmount,
            addCurrency,
            addWallet,
            addMethod,
            clear,
            purchaseModalState,
            showPurchaseModalState,
            openModal,
            closeModal,
            openShowModal,
            closeShowModal,
         }}
      >
         {children}
      </PurchaseContext.Provider>
   )
}
export function usePurchaseContext() {
   const context = useContext(PurchaseContext)

   if (typeof context === 'undefined') {
      throw new Error(
         'usePurchaseContext should be used within the PurchaseContextProvider!',
      )
   }
   return context
}
