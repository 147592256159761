import { Spinner, Table } from 'flowbite-react'
import { useEffect, useState } from 'react'
import { MethodLogo } from '../../../components/CurrencyLogo/CurrencyLogo'
import {
   BtnBack,
   BtnCreate,
   BtnRefresh,
} from '../../../components/EventEssentiels'
import { SearchBar } from '../../../components/NavBar'
import { useApiContext } from '../../../utilis/contexts/ApiContext'
import { useMethodModalContext } from '../../../utilis/contexts/MethodModalContext'
import { useNavContext } from '../../../utilis/contexts/NavContext'
import { updateDataIfNeeded } from '../../../utilis/hooks'
import MethodModal from './MethodModal'
// import PurchaseModal from './CurrencyModal'

export default function Methods() {
   const { handler } = useNavContext()
   const { openModal, openCreate } = useMethodModalContext()
   const [localMethods, setLocalMethods] = useState([])
   const {
      payment_methods,
      isLoadingPaymentMethods,
      errorPaymentMethods,
      refreshPaymentMethods,
   } = useApiContext()

   const handdleSearch = (e) => {
      const word = e.target.value

      word.length > 2
         ? setLocalMethods(
              payment_methods.filter(({ name, wallet }) => {
                 return (
                    name.toLowerCase().includes(word.toLowerCase()) ||
                    wallet?.toLowerCase().includes(word.toLowerCase())
                 )
              }),
           )
         : setLocalMethods(payment_methods)
   }
   useEffect(() => {
      handler('Moyens de Paiement')
   }, [])

   useEffect(() => {
      updateDataIfNeeded(localMethods, payment_methods, setLocalMethods)
      // userError && console.log(userError)
   }, [payment_methods, errorPaymentMethods])
   return (
      <div className="fex-1 flex max-h-full w-full flex-col gap-4 py-2 pr-2">
         <div className="justify- flex flex-col-reverse items-center gap-4 rounded bg-darktext-white p-2  dark:bg-dark-400 md:flex-row">
            <div className="flex flex-row items-center gap-2">
               <BtnBack />
               <BtnCreate title={'Moyen'} onClick={openCreate} />
               <BtnRefresh onClick={() => refreshPaymentMethods()} />
            </div>
            <div className="flex w-full flex-row items-center gap-2">
               <span className="p-2 font-semibold lg:text-6">
                  Moyens Paiement
               </span>
               <SearchBar
                  title={'un Moyen de Paiement'}
                  handdleSearch={handdleSearch}
                  className="flex-1"
               />
            </div>
         </div>
         <div className="overflow-x-scroll">
            <Table>
               <Table.Head>
                  {/* <Table.HeadCell>Event Name</Table.HeadCell> */}
                  <Table.HeadCell>Nom</Table.HeadCell>
                  <Table.HeadCell>Wallet</Table.HeadCell>
                  {/* <Table.HeadCell>Taux à l'achant</Table.HeadCell>
                  <Table.HeadCell>Taux à la vente</Table.HeadCell>
                  <Table.HeadCell>Taux à l'échange</Table.HeadCell>
                  <Table.HeadCell>Crypto</Table.HeadCell> */}
                  {/* <Table.HeadCell>Sponsored</Table.HeadCell> */}
               </Table.Head>
               <Table.Body className="divide-y">
                  {isLoadingPaymentMethods && <TheSpiner />}
                  {payment_methods &&
                  localMethods &&
                  Array.isArray(localMethods) &&
                  localMethods?.length > 0 ? (
                     localMethods?.map((method) => (
                        <Table.Row
                           className=" border-b p-2 dark:border-secondary-500 dark:bg-dark-400"
                           key={method?.id + 'currency_admin'}
                        >
                           <Table.Cell
                              onClick={() => {
                                 openModal(method)
                              }}
                              className=" cursor-pointer  hover:bg-darktext-white hover:font-bold dark:hover:bg-dark"
                           >
                              <span className="flex items-center gap-2">
                                 <MethodLogo img={method?.cover} />
                                 {method?.name}
                              </span>
                           </Table.Cell>
                           <Table.Cell
                              onClick={() => {
                                 openModal(method)
                              }}
                              className="flex cursor-pointer items-center gap-2 hover:bg-darktext-white  hover:font-bold  dark:hover:bg-dark"
                              // className=""
                           >
                              {method?.wallet}
                           </Table.Cell>
                           {/* <Table.Cell>{method?.purchase_rate}%</Table.Cell>
                           <Table.Cell>{method?.selling_rate}%</Table.Cell>
                           <Table.Cell>{method?.swap_rate}%</Table.Cell> */}

                           {/* <Table.Cell>
                              <ActiveTag state={method?.is_crypto} />
                           </Table.Cell> */}
                        </Table.Row>
                     ))
                  ) : (
                     <Table.Row
                        className="dark:bg-dark-400 "
                        // key={event.id + 'events'}
                     >
                        <Table.Cell
                           colSpan={6}
                           // onClick={() => changeEvent(event)}
                           className="cursor-pointer whitespace-nowrap text-center font-medium text-gray-900 dark:text-red-500"
                        >
                           No Data to Display
                        </Table.Cell>
                     </Table.Row>
                  )}
               </Table.Body>
            </Table>
         </div>
         <MethodModal />
      </div>
   )
}

export function TheSpiner() {
   return (
      <Table.Row className=" dark:bg-dark-400 ">
         <Table.Cell colSpan={5} className="m-auto">
            <div className="flex w-full   items-center justify-center">
               <Spinner className="mr-6/12" />
            </div>
         </Table.Cell>
      </Table.Row>
   )
}
