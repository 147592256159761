import { createContext, useContext, useState } from 'react'

export const SwapContext = createContext()

export const SwapProvider = ({ children }) => {
   const [show, setShow] = useState()

   const [swapModalState, setSwapModalState] = useState(false)
   const [showSwapModalState, setShowSwapModalState] = useState(false)
   const [swap, setSwap] = useState({
      from: undefined,
      to: undefined,
      amount: undefined,
      wallet: undefined,
      fees: undefined,
      price: undefined,
      total: undefined,
      method: undefined,
   })

   const swapFrom = (currency) => {
      // getPrice(currency, swap?.to)
      const price = getPrice(currency, swap?.to)
      setSwap({
         from: currency,
         to: swap?.to,
         amount: swap?.amount,
         wallet: swap?.wallet,
         fees:
            swap?.to && parseFloat(swap?.amount) * price * swap?.to?.swap_rate,
         price: price,
         total: swap?.amount && parseFloat(swap.amount) * price,
         method: swap?.method,
      })
   }
   const swapTo = (currency) => {
      // getPrice(swap?.from, currency)
      const price = getPrice(swap?.from, currency)
      setSwap({
         from: swap?.from,
         to: currency,
         amount: swap?.amount,
         wallet: swap?.wallet,
         price: price,
         fees:
            currency && parseFloat(swap?.amount) * price * currency?.swap_rate,
         total: swap?.amount && parseFloat(swap.amount) * price,
         // -
         // parseFloat(swap.amount) * parseFloat(currency.swap_rate)
         method: swap?.method,
      })
   }
   const addAmount = (amount) => {
      // getPrice(swap?.from, swap?.to)\
      const price = getPrice(swap?.from, swap?.to)
      swap?.from &&
         swap?.to &&
         setSwap({
            from: swap?.from,
            to: swap?.to,
            wallet: swap?.wallet,
            amount: amount,
            price: price,
            fees: swap?.to && parseFloat(amount) * price * swap?.to?.swap_rate,
            total: swap?.to && parseFloat(amount) * price,
            // -
            // parseFloat(amount) * parseFloat(swap.to.swap_rate)
            method: swap?.method,
         })
   }
   const addWallet = (wallet) => {
      setSwap({
         from: swap?.from,
         to: swap?.to,
         amount: swap?.amount,
         price: swap?.price,
         wallet: wallet,
         fees: swap?.fees,
         total: swap?.total,
         method: swap?.method,
      })
   }
   const addMethod = (method) => {
      setSwap({
         from: swap?.from,
         to: swap?.to,
         amount: swap?.amount,
         price: swap?.price,
         wallet: swap?.wallet,
         fees: swap?.fees,
         total: swap?.total,
         method: method,
      })
   }
   const flip = () => {
      const price = getPrice(swap?.to, swap?.from)
      setSwap({
         from: swap?.to,
         to: swap?.from,
         amount: swap?.amount,
         wallet: swap?.wallet,
         price: price,
         fees:
            swap?.to && parseFloat(swap?.amount) * price * swap?.to?.swap_rate,
         total: swap?.amount && parseFloat(swap.amount) * price,
         // -
         // parseFloat(swap.amount) * parseFloat(currency.swap_rate)
         method: swap?.method,
      })
   }
   const clear = () => {
      setSwap({
         from: swap?.from,
         to: swap?.to,
         price: swap?.price,
         amount: undefined,
         wallet: undefined,
         fees: undefined,
         total: undefined,
      })
   }

   const getPrice = (from, to) => {
      // console.log(from?.short_name, to?.short_name)
      let price = 1
      if (from?.short_name !== to?.short_name) {
         price = from?.prices?.find(({ symbol }) => {
            return (
               symbol.includes(from?.short_name) &&
               symbol.includes(to?.short_name)
            )
         })
         if (!price) {
            price = to?.prices?.find(({ symbol }) => {
               return (
                  symbol.includes(from?.short_name) &&
                  symbol.includes(to?.short_name)
               )
            })
         }
         if (price) {
            if (price?.symbol?.split(from?.short_name)[0] === '') {
               price = parseFloat(price.price)
            } else {
               price = 1 / parseFloat(price.price)
            }
         }
      } else {
         price = 1
      }

      return price
   }
   const openModal = () => {
      setSwapModalState(true)
   }

   const closeModal = () => {
      setSwapModalState(false)
   }

   const openShowModal = (swap) => {
      setShow(swap)
      setShowSwapModalState(true)
   }

   const closeShowModal = () => {
      setShow()
      setShowSwapModalState(false)
   }

   return (
      <SwapContext.Provider
         value={{
            flip,
            show,
            swap,
            addAmount,
            swapFrom,
            swapTo,
            addWallet,
            addMethod,
            clear,
            swapModalState,
            showSwapModalState,
            openModal,
            closeModal,
            openShowModal,
            closeShowModal,
         }}
      >
         {children}
      </SwapContext.Provider>
   )
}
export function useSwapContext() {
   const context = useContext(SwapContext)

   if (typeof context === 'undefined') {
      throw new Error(
         'useSwapContext should be used within the SwapContextProvider!',
      )
   }
   return context
}
