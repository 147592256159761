import { Button, TextInput } from 'flowbite-react'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
   HiArrowLeft,
   HiLockClosed,
   HiMail,
   HiPhone,
   HiUser,
} from 'react-icons/hi'
import { usePost } from '../../utilis/hooks'

export default function Register({ handler, handleVerify, openPopUp }) {
   // const [checked, setChecked] = useState(true)
   const params = new URLSearchParams(window.location.search)
   const [response, error, isLoading, setPost] = usePost('/auth/register')
   const {
      handleSubmit,
      formState: { errors },
      reset,
      control,
      setValue,
   } = useForm({
      rules: { required: true },
      // defaultValues: { promo_code: params.get('promo_code') },
   })

   const handleClick = (data) => {
      setPost(data)
   }
   useEffect(() => {
      params.get('promo_code') &&
         setValue('promo_code', params.get('promo_code'))
   }, [])

   useEffect(() => {
      if (response?.email) {
         console.log(response)
         openPopUp({
            title: '✅ Enregistrement effectué avec success',
            content:
               'Nous allons passer à la verification de votre adresse mail.',
            accept: {
               title: 'Vérifier',
               handler: () => {
                  reset()
                  handleVerify(response?.email)
               },
            },
         })
      }
      if (error) {
         // console.log(error)
         openPopUp({
            title: 'Registration Error',
            content: error.message,
            decline: {
               title: 'Try Again',
            },
         })
      }
   }, [response, error])
   return (
      <form
         onSubmit={handleSubmit((data) => handleClick(data))}
         className="flex w-full flex-col items-center"
      >
         <div className=" flex w-full flex-col items-start justify-start px-4 pb-2 lg:p-4">
            <span className="urbanist text-6 font-bold lg:text-8">
               Enregistrement
            </span>
            <span className="urbanist">
               Rejoignez-nous ! Remplissez les informations ci-dessous pour
               créer votre compte.
            </span>
         </div>
         <div className="flex w-full flex-col  items-center justify-center space-y-6 px-4 pb-2">
            {/* <HiUser className="material-icons-round text-12 font-medium text-dark md:text-24 dark:text-primary" /> */}
            <div className="flex w-full flex-col gap-2 sm:gap-3">
               <Controller
                  control={control}
                  name="names"
                  render={({ field }) => (
                     <TextInput
                        rightIcon={HiUser}
                        {...field}
                        color={errors.first_name && 'failure'}
                        id="name"
                        type="text"
                        placeholder="Votre nom"
                        // value={email}
                        // onChange={(event) => setEmail(event.target.value)}
                        required
                     />
                  )}
               />
               <Controller
                  control={control}
                  name="email"
                  render={({ field }) => (
                     <TextInput
                        rightIcon={HiMail}
                        {...field}
                        color={errors.email && 'failure'}
                        id="email"
                        type="mail"
                        placeholder="name@company.com"
                        // value={email}
                        // onChange={(event) => setEmail(event.target.value)}
                        required
                     />
                  )}
               />
               <Controller
                  control={control}
                  name="password"
                  render={({ field }) => (
                     <TextInput
                        rightIcon={HiLockClosed}
                        {...field}
                        color={errors.password && 'failure'}
                        id="password"
                        type="password"
                        placeholder="Mot de passe"
                        required
                     />
                  )}
               />
               <Controller
                  control={control}
                  name="confirm_password"
                  render={({ field }) => (
                     <TextInput
                        rightIcon={HiLockClosed}
                        {...field}
                        color={errors.confirm_password && 'failure'}
                        id="confirm-password"
                        type="password"
                        placeholder="Confirmer le mot de passe"
                        required
                     />
                  )}
               />
               <Controller
                  control={control}
                  name="telephone"
                  render={({ field }) => (
                     <TextInput
                        rightIcon={HiPhone}
                        {...field}
                        color={errors.telephone && 'failure'}
                        id="telephone"
                        type="tel"
                        placeholder="Telephone"
                        // value={email}
                        // onChange={(event) => setEmail(event.target.value)}
                        required
                     />
                  )}
               />
               {params.get('promo_code') && (
                  <Controller
                     control={control}
                     name="promo_code"
                     render={({ field }) => (
                        <TextInput
                           // rightIcon={}
                           {...field}
                           color={errors.telephone && 'failure'}
                           id="telephone"
                           type="tel"
                           // placeholder="Promo Code"
                           disabled
                           // value={params.get('promo_code')}
                           // onChange={(event) => setEmail(event.target.value)}
                           required
                        />
                     )}
                  />
               )}
            </div>
            <div className="flex w-full flex-col items-center justify-center gap-1">
               <Button
                  type="submit"
                  disabled={
                     errors.first_name ||
                     errors.email ||
                     errors.username ||
                     errors.password ||
                     errors.telephone
                  }
                  isProcessing={isLoading}
                  className="w-full bg-primary hover:bg-secondary dark:bg-secondary"
               >
                  Enregistrer
               </Button>
               <span className="font-light text-dark dark:text-darktext">
                  vous avez déjà un compte ?
               </span>
               <Button
                  onClick={() => handler('login')}
                  color="gray"
                  className="flex w-full items-center gap-2 border-2 text-primary dark:border-secondary dark:bg-dark dark:text-darktext"
               >
                  <HiArrowLeft className="mr-2 text-6" />
                  <span>Connexion</span>
               </Button>
            </div>
         </div>
      </form>
   )
}
