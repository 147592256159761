import { createContext, useContext, useState } from 'react'

export const SellingContext = createContext()

export const SellingProvider = ({ children }) => {
   const [show, setShow] = useState()
   const [sellingModalState, setPurchaseModalState] = useState(false)
   const [showSellingModalState, setShowSellingModalState] = useState(false)

   const [selling, setPurchase] = useState({
      currency: undefined,
      amount: undefined,
      price: undefined,
      wallet: undefined,
      fees: undefined,
      total: undefined,
      method: undefined,
   })

   const addCurrency = (currency) => {
      setPurchase({
         currency: currency,
         amount: selling?.amount,
         price: currency?.price,
         wallet: selling?.wallet,
         fees:
            selling?.amount &&
            (selling.amount * currency.selling_rate).toFixed(2),
         total:
            selling?.amount &&
            (
               parseFloat(selling.amount) -
               parseFloat(selling.amount) * parseFloat(currency?.selling_rate)
            ).toFixed(2),
         method: selling?.method,
      })
   }
   const addAmount = (amount) => {
      selling?.currency &&
         setPurchase({
            currency: selling?.currency,
            wallet: selling?.wallet,
            amount: amount,
            price: selling?.price,

            fees:
               selling?.currency &&
               (amount * selling?.currency.selling_rate).toFixed(2),
            total:
               selling?.currency &&
               (
                  parseFloat(amount) -
                  parseFloat(amount) *
                     parseFloat(selling?.currency?.selling_rate)
               ).toFixed(2),
            method: selling?.method,
         })
   }
   const addWallet = (wallet) => {
      setPurchase({
         currency: selling?.currency,
         amount: selling?.amount,
         price: selling?.price,
         wallet: wallet,
         fees: selling?.fees,
         total: selling?.total,
         method: selling?.method,
      })
   }
   const addMethod = (method) => {
      setPurchase({
         currency: selling?.currency,
         amount: selling?.amount,
         price: selling?.price,
         wallet: selling?.wallet,
         fees: selling?.fees,
         total: selling?.total,
         method: method,
      })
   }
   const clear = (method) => {
      setPurchase({
         currency: selling?.currency,
         price: selling?.price,
         amount: undefined,
         wallet: undefined,
         fees: undefined,
         total: undefined,
         method: method,
      })
   }

   const openModal = () => {
      setPurchaseModalState(true)
   }

   const closeModal = () => {
      setPurchaseModalState(false)
   }
   const openShowModal = (sell) => {
      setShow(sell)
      setShowSellingModalState(true)
   }

   const closeShowModal = () => {
      setShow()
      setShowSellingModalState(false)
   }

   return (
      <SellingContext.Provider
         value={{
            show,
            selling,
            addAmount,
            addCurrency,
            addWallet,
            addMethod,
            clear,
            sellingModalState,
            showSellingModalState,
            openModal,
            closeModal,
            openShowModal,
            closeShowModal,
         }}
      >
         {children}
      </SellingContext.Provider>
   )
}
export function useSellingContext() {
   const context = useContext(SellingContext)

   if (typeof context === 'undefined') {
      throw new Error(
         'useSellingContext should be used within the SellingContextProvider!',
      )
   }
   return context
}
