import { FaCoins, FaMoneyBill } from 'react-icons/fa6'
import { assetsLink } from '../../utilis/contexts/ApiContext'

export default function CurrencyLogo({ img, className }) {
   return (
      <div className={className + ''}>
         {img ? (
            <img className="h-6 w-6 " src={assetsLink + img} alt="logo" />
         ) : (
            <FaCoins />
         )}
      </div>
   )
}
export function MethodLogo({ img }) {
   return (
      <div className="h-10 w-10 overflow-hidden rounded">
         {img ? <img src={assetsLink + img} alt="logo" /> : <FaMoneyBill />}
      </div>
   )
}
