import { Avatar, Button, TextInput } from 'flowbite-react'
import { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { BiSupport } from 'react-icons/bi'
import { FaWhatsapp } from 'react-icons/fa'
import { FaPencil, FaWallet } from 'react-icons/fa6'
import {
   HiChevronDown,
   HiLockClosed,
   HiMail,
   HiPencil,
   HiPhone,
   HiRefresh,
   HiUser,
} from 'react-icons/hi'
import { useApiContext } from '../../../utilis/contexts/ApiContext'
import { useNavContext } from '../../../utilis/contexts/NavContext'
import { usePopUpContext } from '../../../utilis/contexts/PopUpContext'
import { useSessionContext } from '../../../utilis/contexts/SessionContext'
import { usePost } from '../../../utilis/hooks'
import { useScreenWidth } from '../../../utilis/hooks/display'

export default function Profile() {
   const { handler } = useNavContext()
   const [isSmallDevice, isMobile] = useScreenWidth()
   const { refresh } = useApiContext()
   useEffect(() => {
      refresh()
      handler('Mon Compte')
      isSmallDevice && console.log()
   }, [])

   return (
      <div className="flex h-full w-full flex-col gap-4 pb-2 md:flex-row md:justify-center">
         <Account />
         {!isMobile && (
            <div className=" flex w-full flex-col gap-4 md:h-full lg:w-5/12">
               <Wallet />
               <Support />
            </div>
         )}
      </div>
   )
}
function Account() {
   const [edit, setEdit] = useState(false)
   const [isSmallDevice, isMobile] = useScreenWidth()
   const [identity, setIdentity] = useState(false)
   const [password, setPassword] = useState(false)
   const [userId, setUserId] = useState()
   const [thePassword, setThePassword] = useState()
   const [oldPassword, setOldPassword] = useState()
   const [confirmPassword, setConfirmPassword] = useState()
   const [editIdentity, setEditIdentity] = useState(false)
   const { openPopUp, closePopUp } = usePopUpContext()
   const { startSession } = useSessionContext()

   const handleEdit = () => {
      setEdit(!edit)
   }

   useEffect(() => {
      isMobile && edit && setEdit(false)
      !isMobile && !edit && setEdit(true)
      isSmallDevice && console.log()
   }, [isMobile])

   const [response, error, isLoading, setPostIdentity] =
      usePost('/api/users/update')
   const [responsePassword, errorPassword, isLoadingPassword, setPostPassword] =
      usePost('/api/users/update_password')

   const { loggedUser } = useSessionContext()

   const {
      // register,
      handleSubmit,
      formState: { errors },
      setValue,
      // watch
      control,
   } = useForm({
      rules: { required: true, minLength: 6 },
   })

   const { destroySession, resetPassword } = useSessionContext()

   // const checkInputs = () => {
   //    return errors.names || errors.telephone
   //    // return true
   // }
   const handleClick = (data) => {
      // console.log(data)
      setPostIdentity(data)
   }

   const handlePostPassword = () => {
      if (resetPassword) {
         setPostPassword({
            user_id: userId,
            password: thePassword,
         })
      } else {
         setPostPassword({
            user_id: userId,
            password: thePassword,
            old_password: oldPassword,
         })
      }
   }
   const handleOldPassword = (pass) => {
      setOldPassword(pass)
   }
   const handlePassword = (pass) => {
      setThePassword(pass)
   }
   const handleConfirmPassword = (pass) => {
      setConfirmPassword(pass)
   }
   const handleIdentity = () => {
      setPassword(false)
      setIdentity(!identity)
   }
   const handleEditIdentity = () => {
      // console.log('edit')
      identity && setEditIdentity(!editIdentity)
   }
   const handleEditPassword = () => {
      setIdentity(false)
      setPassword(!password)
   }
   useEffect(() => {
      // setValue('email', loggedUser.email)
      setValue('user_id', loggedUser.id)
      setValue('names', loggedUser.names)
      setValue('telephone', loggedUser.telephone)
      setUserId(loggedUser.id)
   }, [])

   useEffect(() => {
      if (response?.email) {
         if (response?.email) {
            // console.log(response)
            // startSess
            startSession(response)
            openPopUp({
               title: '✅ Modification effectué avec succès.',
               content: `Modification effectué avec succès, @${response.names} ✨`,
               default: {
                  title: '👊🏾',
                  handler: () => {
                     closePopUp()
                     handleIdentity()
                     handleEditIdentity()
                  },
               },
            })
         } else {
            openPopUp({
               title: "⚠️ Erreur de Modification d'identité",
               content:
                  "Oups! Une Erreur s'ait produite, Veuillez verifier votre connexion internet puis réessayer",
               decline: {
                  title: 'Réessayer',
               },
            })
         }
      }
      if (error) {
         if (error?.code === 403 || error?.request?.status === 403) {
            openPopUp({
               title: 'Session Expirée ⚠️',
               content:
                  "Oops! Votre session a expirée, vous allez devoir vous reconnecter pour continuer l'operation",
               default: {
                  title: 'Connexion',
                  handler: destroySession,
               },
            })
         } else {
            openPopUp({
               title: '⚠️ Erreur de Modification de mot de passe',
               content: error.message,
               decline: {
                  title: 'Réessayer',
               },
            })
         }
      }
   }, [response, error])

   useEffect(() => {
      if (responsePassword) {
         if (responsePassword === 'success') {
            openPopUp({
               title: '✅ Mot de passe modifié avec succès.',
               content: `Votre Mot de passe a été modifié avec succès ✨`,
               default: {
                  title: '👊🏾',
                  handler: () => {
                     setThePassword()
                     setConfirmPassword()
                     handleEditPassword()
                     closePopUp()
                  },
               },
            })
         } else {
            openPopUp({
               title: "⚠️ Erreur d'enregistrement",
               content: errorPassword.message,
               decline: {
                  title: 'Réessayer',
               },
            })
         }
      }
      if (errorPassword) {
         // console.log(error)
         if (
            errorPassword?.code === 403 ||
            errorPassword?.request?.status === 403
         ) {
            openPopUp({
               title: 'Session Expirée ⚠️',
               content:
                  "Oops! Votre session a expirée, vous allez devoir vous reconnecter pour continuer l'operation",
               default: {
                  title: 'Connexion',
                  handler: destroySession,
               },
            })
         } else {
            openPopUp({
               title: "⚠️ Erreur d'enregistrement",
               content: errorPassword.message,
               decline: {
                  title: 'Réessayer',
               },
            })
         }
      }
   }, [responsePassword, errorPassword])

   useEffect(() => {
      handleEdit()
      handleEditIdentity()
   }, [resetPassword])
   return (
      <div className=" flex w-full flex-col gap-4  lg:w-6/12">
         <div className="flex w-full flex-col gap-4">
            {isMobile && <Wallet />}
            <div className="flex w-full flex-row items-center justify-between rounded-lg bg-darktext-white p-4 dark:bg-secondary">
               <div className="flex-flex-col gap-2">
                  <Avatar rounded status="online">
                     <div className="space-y-1 font-medium dark:text-white">
                        <div>{loggedUser?.names}</div>
                        <div className="flex text-sm text-gray-500 dark:text-gray-400">
                           <span>{loggedUser?.email}, </span>
                           {/* <span> {loggedUser?.telephone}</span> */}
                        </div>
                     </div>
                  </Avatar>
               </div>
               <div
                  onClick={handleEdit}
                  className="flex cursor-pointer items-center justify-center rounded-full p-4 hover:bg-darktext hover:text-white dark:hover:bg-dark-400"
               >
                  <FaPencil />
               </div>
            </div>
            {edit && (
               <>
                  <div className="flex w-full flex-row items-center justify-between px-2">
                     <span
                        onClick={() => handleIdentity()}
                        className="cursor-pointer font-semibold text-gray-600 dark:text-gray-400"
                     >
                        Données Personnelles
                     </span>
                     {!identity && (
                        <span
                           onClick={handleIdentity}
                           className="flex cursor-pointer items-center justify-center rounded-full p-2 hover:bg-gray-300 dark:hover:bg-dark"
                        >
                           <HiChevronDown className="text-6 text-primary dark:text-gray-400" />
                        </span>
                     )}
                     {identity && (
                        <span
                           onClick={handleEditIdentity}
                           className="flex cursor-pointer items-center justify-center rounded-full p-2 hover:bg-gray-300 dark:hover:bg-dark"
                        >
                           <HiPencil className="text-6 text-primary dark:text-gray-400" />
                        </span>
                     )}
                  </div>
                  {identity && !password && (
                     <form
                        onSubmit={handleSubmit((data) => handleClick(data))}
                        className="rounded-lg bg-darktext-white p-2 dark:bg-secondary"
                     >
                        <div className="flex w-full  flex-col gap-2 rounded-lg   sm:gap-3">
                           <Controller
                              control={control}
                              name="user_id"
                              render={({ field }) => (
                                 <TextInput {...field} type="hidden" />
                              )}
                           />
                           <Controller
                              control={control}
                              name="names"
                              render={({ field }) => (
                                 <TextInput
                                    rightIcon={HiUser}
                                    {...field}
                                    color={errors.names && 'failure'}
                                    id="name"
                                    disabled={!editIdentity}
                                    type="text"
                                    placeholder="Votre nom"
                                    // onChange={(event) => setEmail(event.target.value)}
                                    required
                                 />
                              )}
                           />

                           <TextInput
                              rightIcon={HiMail}
                              id="email"
                              type="email"
                              disabled
                              value={loggedUser.email}
                              placeholder="name@domain.com"
                              // onChange={(event) => setEmail(event.target.value)}
                              required
                           />
                           <Controller
                              control={control}
                              name="telephone"
                              render={({ field }) => (
                                 <TextInput
                                    rightIcon={HiPhone}
                                    {...field}
                                    // {...register({
                                    //    required: true,
                                    //    // eslint-disable-next-line no-useless-escape
                                    //    pattern: /^\+?\d[\d\s\.\-]+$/,
                                    // })}
                                    color={errors.telephone && 'failure'}
                                    id="telephone"
                                    disabled={!editIdentity}
                                    type="tel"
                                    placeholder="Telephone"
                                    // onChange={(event) => setEmail(event.target.value)}
                                    required
                                    // onClick={handleSubmit((e) => {
                                    //    console.log('cl12')
                                    //    checkInputs && handleClick(e)
                                    // })}
                                 />
                              )}
                           />
                        </div>
                        <div className="flex w-full flex-col items-center justify-center gap-2 pt-2">
                           {editIdentity && (
                              <Button
                                 type="submit"
                                 disabled={
                                    errors.names ||
                                    // errors.email ||
                                    errors.telephone
                                 }
                                 isProcessing={isLoading}
                                 className="w-full bg-primary hover:bg-dark"
                              >
                                 Enregistrer
                              </Button>
                           )}
                        </div>
                     </form>
                  )}
                  <div className="flex w-full flex-row items-center justify-between p-2">
                     <span
                        onClick={handleEditPassword}
                        className="cursor-pointer font-semibold text-gray-600 dark:text-gray-400"
                     >
                        Modifier le Mot de Passe
                     </span>
                     <span
                        onClick={handleEditPassword}
                        className="flex cursor-pointer items-center justify-center rounded-full p-2 hover:bg-gray-300 dark:hover:bg-dark"
                     >
                        <HiPencil className="text-6 text-primary dark:text-gray-400" />
                     </span>
                  </div>
                  {password && !identity && (
                     <div className="rounded-lg bg-darktext-white p-2 dark:bg-secondary ">
                        <div className="flex w-full flex-col gap-2 sm:gap-3">
                           {!resetPassword && (
                              <TextInput
                                 rightIcon={HiLockClosed}
                                 // color={}
                                 id="password"
                                 type="password"
                                 onChange={(e) =>
                                    handleOldPassword(e.target.value)
                                 }
                                 placeholder="Entrer votre ancien mot de Passe"
                                 required
                              />
                           )}
                           <TextInput
                              rightIcon={HiLockClosed}
                              // color={}
                              id="password"
                              type="password"
                              onChange={(e) => handlePassword(e.target.value)}
                              placeholder="Entrer le nouveau mot de Passe"
                              required
                           />
                           <TextInput
                              rightIcon={HiLockClosed}
                              color={
                                 thePassword !== confirmPassword
                                    ? 'failure'
                                    : ''
                              }
                              id="confirm-password"
                              type="password"
                              onChange={(e) =>
                                 handleConfirmPassword(e.target.value)
                              }
                              placeholder="Confirmer le mot de Passe"
                              required
                           />
                        </div>
                        <div className="flex w-full flex-col items-center justify-center gap-2 pt-2">
                           <Button
                              disabled={thePassword !== confirmPassword}
                              isProcessing={isLoadingPassword}
                              onClick={handlePostPassword}
                              className="w-full bg-primary hover:bg-dark"
                           >
                              Modifier
                           </Button>
                        </div>
                     </div>
                  )}
               </>
            )}
            {isMobile && <Support />}
         </div>
      </div>
   )
}
export function Wallet() {
   const { user, refreshAll, isLoading, error, refresh } = useApiContext()
   // console.log(user?.balance?.sold)
   const ref = useRef(null)
   const { openPopUp, closePopUp } = usePopUpContext()
   const { destroySession } = useSessionContext()

   useEffect(() => {
      !isLoading && ref?.current?.classList.remove('animate-spin')
   }, [isLoading])

   // useEffect(() => {
   //    refresh()
   // }, [])

   useEffect(() => {
      if (error && !isLoading) {
         if (error?.code === 403 || error?.request?.status === 403) {
            openPopUp({
               title: 'Session Expirée ⚠️',
               content:
                  "Oops! Votre session a expirée, vous allez devoir vous reconnecter pour continuer l'operation",
               default: {
                  title: 'Connexion',
                  handler: destroySession,
               },
               decline: {
                  title: 'Plus tard',
                  handler: refresh,
               },
            })
         } else {
            closePopUp()
         }
      }
   }, [error])

   function spin() {
      ref && ref?.current?.classList.add('animate-spin')

      // console.log(112)
      const intervalId = setTimeout(() => {
         // console.log(111)
         ref && ref?.current?.classList.remove('animate-spin')
      }, 1 * 1000)
      // intervalId
      clearTimeout(intervalId)
   }
   return (
      <div className="flex w-full flex-col gap-4 ">
         <div className="flex w-full flex-row items-center justify-between rounded-lg bg-darktext-white p-4 dark:bg-secondary">
            <div className="flex-flex-col gap-2">
               <FaWallet className="text-7 text-primary dark:text-tertiery" />
               <span className="font-semibold">Balance</span>
            </div>
            <div className="flex flex-row items-center gap-2">
               <span
                  ref={ref}
                  className="cursor-pointer text-6"
                  onClick={() => {
                     spin()
                     refreshAll()
                  }}
               >
                  <HiRefresh />
               </span>
               <span className="text- rounded bg-dark-400 px-2 py-1 text-7 font-bold text-white dark:bg-dark-500 md:text-7">
                  {user?.balance ? user?.balance?.sold : '0.00'}{' '}
                  <span className="text-4 font-semibold">USDT</span>
                  {/* {JSON.stringify(user?.balance)} */}
               </span>
            </div>
         </div>
         {/* <div className="flex  flex-row justify-between gap-4">
            <div className="flex w-full flex-col items-center justify-between rounded-lg bg-darktext-white p-2 dark:bg-secondary">
               <FaArrowCircleDown className=" text-6 text-primary dark:text-tertiery" />
               <span className="font-light">Deposer</span>
            </div>
            <div className="flex w-full flex-col items-center justify-between rounded-lg bg-darktext-white p-2 dark:bg-secondary">
               <FaArrowCircleUp className=" text-6 text-primary dark:text-tertiery" />
               <span className="font-light">Rétiter</span>
            </div>
         </div> */}
      </div>
   )
}

function Support() {
   return (
      <div className="flex w-full flex-row items-center justify-between gap-2 rounded-lg bg-darktext-white p-4 dark:bg-secondary">
         <div
            // onClick={handleEdit}
            className="flex cursor-pointer items-center justify-center rounded-full p-2 dark:hover:bg-dark-400"
         >
            <BiSupport className="text-6" />
         </div>
         <div className="flex-flex-col gap-2">
            <div className="space-y-1 font-medium dark:text-white">
               <div>Besoin d'aide ?</div>
               <div className="flex text-sm text-gray-500 dark:text-gray-400">
                  <span>
                     Entrez en contact avec notre service client maintenant.{' '}
                  </span>
                  {/* <span> {loggedUser?.telephone}</span> */}
               </div>
            </div>
         </div>
         <a
            target="_blank"
            href="https://api.whatsapp.com/send?phone=+243971270823&text=Bonjour MudeCapial, j'ai besoin d'aide"
            rel="noreferrer"
            // onClick={handleEdit}
            className="flex cursor-pointer items-center justify-center rounded-full p-4 hover:bg-darktext hover:text-white dark:hover:bg-dark-400"
         >
            <FaWhatsapp className="text-6" />
         </a>
      </div>
   )
}
