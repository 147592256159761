import { Route, Routes } from 'react-router-dom'

import { CurrencyModalProvider } from '../../utilis/contexts/CurrencyModalContext'
import { MethodModalProvider } from '../../utilis/contexts/MethodModalContext'
import Profile from '../client/profile'
import Currencies from './currencies'
import Home from './dashboard'
import Layout from './layout'
import Methods from './methods'
import Transactions from './transactions'
import Users from './users'

export default function Admin() {
   return (
      <CurrencyModalProvider>
         <MethodModalProvider>
            <Layout>
               <Routes>
                  <Route path="/" element={<Home />} />
                  {/* <Route path="/transactions" element={<Transactions />} />
                  <Route path="/parrainages" element={<Parrainange />} />*/}
                  <Route path="/devises" element={<Currencies />} />
                  <Route path="/methods" element={<Methods />} />
                  <Route path="/transactions/*" element={<Transactions />} />
                  <Route path="/users" element={<Users />} />
                  <Route path="/account" element={<Profile />} />
                  <Route path="*" element={<Home />} />
               </Routes>
            </Layout>
         </MethodModalProvider>
      </CurrencyModalProvider>
   )
}
