import {
   CategoryScale,
   Chart as ChartJS,
   Legend,
   LinearScale,
   LineElement,
   PointElement,
   Title,
   Tooltip,
} from 'chart.js'
import { Spinner } from 'flowbite-react'
import { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import {
   CgArrowBottomLeftR,
   CgArrowsExchangeAltV,
   CgArrowTopRightR,
} from 'react-icons/cg'
import { HiUser } from 'react-icons/hi'
import { IoReload } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { useNavContext } from '../../../utilis/contexts/NavContext'
import { useFetch } from '../../../utilis/hooks'
// import { Line } from 'react-chartjs-2'

export default function Dashboard() {
   // const navigate = useNavigate()
   // const [localData, setLo]
   // const [labels, setLabels] = useState()
   const [chart, setChart] = useState()
   const [
      datas,
      isLoadingData,
      // errorData,
      // ,refreshData
   ] = useFetch('/api/get_data')
   const { handler } = useNavContext()
   useEffect(() => {
      handler('Tableau de Bord')
      // id && console.log()
   })

   ChartJS.register(
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      Title,
      Tooltip,
      Legend,
   )

   const options = {
      responsive: true,
      plugins: {
         legend: {
            position: 'top',
         },
         title: {
            display: false,
            text: 'Chart.js Line Chart',
         },
      },
   }

   // const labels = ['BTC', 'USDT', 'ETH', 'DOGE']
   // const datass = [
   //    { purchases: 11, sellings: 3, swaps: 10 },
   //    {
   //       purchases: 0,
   //       sellings: 0,
   //       swaps: 0,
   //    },
   //    {
   //       purchases: 2,
   //       sellings: 1,
   //       swaps: 9,
   //    },
   //    {
   //       purchases: 0,
   //       sellings: 0,
   //       swaps: 1,
   //    },
   // ]

   useEffect(() => {
      if (datas && datas?.charts) {
         console.log(datas)
         let labels = datas?.labels
         datas &&
            setChart({
               labels,
               datasets: [
                  {
                     label: 'ACHATS',
                     data: datas?.charts?.map((data) => data?.purchases),
                     borderColor: 'rgb(255, 99, 132)',
                     backgroundColor: 'rgba(255, 99, 132, 0.5)',
                  },
                  {
                     label: 'VENTES',
                     data: datas?.charts?.map((data) => data?.sellings),
                     borderColor: 'rgb(53, 162, 235)',
                     backgroundColor: 'rgba(53, 162, 235, 0.5)',
                  },
                  {
                     label: 'ECHANGES',
                     data: datas?.charts?.map((data) => data?.swaps),
                     borderColor: 'rgb(153, 20, 235)',
                     backgroundColor: 'rgba(153, 62, 235, 0.5)',
                  },
               ],
            })
      }
   }, [datas])
   // const data = {
   //    labels,
   //    datasets: [
   //       {
   //          label: 'ACHATS',
   //          data: datass.map((data) => data.purchases),
   //          borderColor: 'rgb(255, 99, 132)',
   //          backgroundColor: 'rgba(255, 99, 132, 0.5)',
   //       },
   //       {
   //          label: 'VENTES',
   //          data: datass.map((data) => data.sellings),
   //          borderColor: 'rgb(53, 162, 235)',
   //          backgroundColor: 'rgba(53, 162, 235, 0.5)',
   //       },
   //       {
   //          label: 'ECHANGES',
   //          data: datass.map((data) => data.swaps),
   //          borderColor: 'rgb(153, 20, 235)',
   //          backgroundColor: 'rgba(153, 62, 235, 0.5)',
   //       },
   //    ],
   // }

   return (
      <div className="flex w-full flex-col md:pt-2">
         <div className="grid w-full grid-flow-row grid-cols-2 items-center justify-between gap-2 sm:grid-cols-3  sm:gap-4  md:grid-cols-4">
            <Box
               className="min-w-3/12"
               Icon={CgArrowTopRightR}
               title={'Achats'}
               to={'/admin/transactions'}
               total={datas?.data?.purchases}
               isLoading={isLoadingData}
            />
            <Box
               Icon={CgArrowBottomLeftR}
               className="min-w-3/12"
               title={'Ventes'}
               to={'/admin/transactions/sellings'}
               total={datas?.data?.sellings}
               isLoading={isLoadingData}
            />
            <Box
               className="min-w-3/12"
               Icon={CgArrowsExchangeAltV}
               title={'Echanges'}
               to={'/admin/transactions/swaps'}
               total={datas?.data?.swaps}
               isLoading={isLoadingData}
            />
            <Box
               className="min-w-3/12"
               Icon={HiUser}
               title={'Utilisateurs'}
               to={'/admin/users'}
               total={datas?.data?.users}
               isLoading={isLoadingData}
            />
            {/* <Box
               className="min-w-3/12"
               Icon={HiOfficeBuilding}
               title={'Venues'}
               to={'/admin/venues'}
            />
            <Box
               className="min-w-3/12"
               Icon={HiUser}
               title={'Users'}
               to={'/admin/users'}
            /> */}
            {/* <Box Icon={HiUser} title={'Users'} /> */}
         </div>
         {/* {chart && ( */}
         <div className="grid w-full grid-flow-row grid-cols-1 gap-2 py-2 sm:gap-4 sm:py-4 md:grid-cols-2">
            <div className="flex flex-row gap-4 rounded bg-darktext-white p-4 dark:bg-dark-400 ">
               {chart ? <Line options={options} data={chart} /> : <Spinner />}
            </div>
            <div className="flex flex-row gap-4 rounded bg-darktext-white p-4 dark:bg-dark-400 ">
               {chart ? <Line options={options} data={chart} /> : <Spinner />}
            </div>
         </div>
         {/* )} */}
      </div>
   )
}

function Box({ Icon, title, to, className, total, isLoading }) {
   return (
      <Link
         to={'' + to}
         className={
            className +
            'flex flex-col items-center justify-center rounded bg-darktext-white p-4   text-dark-400 dark:bg-dark-400 dark:text-darktext'
         }
      >
         <div className="flex w-full  flex-row items-center justify-between gap-2">
            <div className="items-left flex flex-col gap-2">
               <span className="font-semibold">{title}</span>
               {isLoading && !total ? (
                  <span className="flex animate-spin items-center justify-center">
                     <IoReload className="text-6 text-gray-400" />
                  </span>
               ) : (
                  <span className="text-6 font-bold">{total}</span>
               )}
            </div>
            <Icon className=" p-1 text-8  md:text-10" />
         </div>
         {/* <span>lorem ipsum</span> */}
      </Link>
   )
}
