export function randomKey() {
   return (Math.floor(Math.random()) * 230).toString()
}

export async function pasteTextFromClipboard() {
   try {
      if (navigator.clipboard && navigator.clipboard.writeText) {
         // Read the contents of the clipboard.
         const clipboardText = await navigator.clipboard.readText()
         // Display the text in a web page.
         return clipboardText
      } else {
         console.log('Text past to the not working.')
      }
   } catch (err) {
      console.error('Failed to read clipboard contents: ' + err)
   }
}

export async function copyTextToClipboard(text) {
   try {
      // Write the input text to the clipboard.
      if (navigator.clipboard && navigator.clipboard.writeText) {
         await navigator.clipboard.writeText(text)
         console.log('Text copied to the clipboard.')
         // Use the writeText() method here.
         return true
      } else {
         // Handle the case where the method is not available.
         console.log('Text copied to the not working.')
         return false
      }
   } catch (err) {
      console.error('Failed to write clipboard contents: ' + err)
      return false
   }
}

export function disableZoom() {
   let lastTouchEnd = 0
   document.addEventListener(
      'touchend',
      function (event) {
         const now = new Date().getTime()
         if (now - lastTouchEnd <= 300) {
            event.preventDefault()
         }
         lastTouchEnd = now
      },
      false,
   )
}

export function countdown(minutes, setState) {
   let seconds = minutes * 60
   const interval = setInterval(() => {
      const minutesLeft = Math.floor(seconds / 60)
      const secondsLeft = seconds % 60
      setState(`${minutesLeft}:${secondsLeft < 10 ? '0' : ''}${secondsLeft}`)
      if (seconds <= 0) {
         clearInterval(interval)
         setState()
      } else {
         seconds--
      }
   }, 1000)
}
