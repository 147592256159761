import { useEffect, useState } from 'react'
// import { FaChartLine } from 'react-icons/fa6'
import { MdInsertChart } from 'react-icons/md'
import logo from '../../../../assets/imgs/logo.png'
export default function Logo({ size = '8', className }) {
   const [fontSize, setFontSize] = useState('text-8')
   const handleFont = (size) => {
      return 'text-' + size
   }
   useEffect(() => {
      setFontSize(handleFont(size))
   }, [size])
   return (
      <div
         className={
            'flex items-center justify-center gap-1 font-bold ' +
            fontSize +
            ' ' +
            className
         }
      >
         {logo ? (
            <img src={logo} alt="logo" className="mr-1 h-8" />
         ) : (
            <MdInsertChart className="mr-2 text-primary dark:text-tertiery-500" />
         )}
         <span className="text-secondary dark:text-bg">MUDE</span>
         <span className="">|</span>
         <span className="text-secondary dark:text-bg">CAPITAL</span>
      </div>
   )
}
