import { Table } from 'flowbite-react'
import { useEffect } from 'react'
import CurrencyLogo from '../../../../components/CurrencyLogo/CurrencyLogo'
import {
   BtnBack,
   BtnRefresh,
   StateTag,
} from '../../../../components/EventEssentiels'
import { SearchBar } from '../../../../components/NavBar'
import { useApiContext } from '../../../../utilis/contexts/ApiContext'
import { useNavContext } from '../../../../utilis/contexts/NavContext'
import { useSellingContext } from '../../../../utilis/contexts/SellingContext'
import { updateDataIfNeeded, useFetch } from '../../../../utilis/hooks'
import { TheSpiner } from '../purchases'
import SellingModal from './SellingModal'

export default function Sellings() {
   const [localUsers, isLoadingUsers, userError, refresh] =
      useFetch('/api/sellings')
   const { sellings, setSellings } = useApiContext()
   const { handler } = useNavContext()
   const { openShowModal } = useSellingContext()

   const handdleSearch = (e) => {
      const word = e.target.value

      word.length > 3
         ? setSellings(
              localUsers.filter(({ reference, currency }) => {
                 return (
                    reference?.toLowerCase().includes(word.toLowerCase()) ||
                    (currency &&
                       currency?.name
                          ?.toLowerCase()
                          .includes(word.toLowerCase()))
                 )
              }),
           )
         : setSellings(localUsers)
   }

   useEffect(() => {
      handler('Ventes')
   })
   useEffect(() => {
      updateDataIfNeeded(sellings, localUsers, setSellings)
      // userError && console.log(userError)
   }, [localUsers, userError])
   return (
      <div className="flex max-h-screen w-full flex-col gap-4 py-2 pr-2">
         <div className="justify flex flex-row items-center gap-4 rounded bg-darktext-white  p-2 dark:bg-dark-400">
            <BtnBack />
            <BtnRefresh onClick={() => refresh()} />
            <span className="p-2 font-semibold lg:text-6">Ventes</span>
            {/* <BtnCreate /> */}
            <SearchBar
               title={'une Vente'}
               handdleSearch={handdleSearch}
               className="flex-1"
            />
            {/* <Datepicker /> */}
         </div>
         <div className="overflow-x-scroll">
            <Table>
               <Table.Head>
                  {/* <Table.HeadCell>Event Name</Table.HeadCell> */}
                  <Table.HeadCell>N° Reference</Table.HeadCell>
                  <Table.HeadCell>Devise</Table.HeadCell>
                  <Table.HeadCell>Montant</Table.HeadCell>
                  <Table.HeadCell>Frais</Table.HeadCell>

                  <Table.HeadCell>State</Table.HeadCell>
                  {/* <Table.HeadCell>Sponsored</Table.HeadCell> */}
               </Table.Head>
               <Table.Body className="divide-y">
                  {isLoadingUsers && <TheSpiner />}
                  {sellings && sellings.length > 0 ? (
                     sellings.map((purchase) => (
                        <Table.Row
                           className=" border-b p-2 dark:border-secondary-500 dark:bg-dark-400"
                           key={purchase?.id + 'sellings_admin'}
                        >
                           <Table.Cell
                              onClick={() => {
                                 openShowModal(purchase)
                              }}
                              className=" cursor-pointer  hover:bg-darktext-white hover:font-bold dark:hover:bg-dark"
                           >
                              {purchase?.reference}
                           </Table.Cell>
                           <Table.Cell
                              onClick={() => {
                                 openShowModal(purchase)
                              }}
                              className="flex cursor-pointer items-center gap-2 hover:bg-darktext-white  hover:font-bold  dark:hover:bg-dark"
                           >
                              <CurrencyLogo img={purchase?.currency?.cover} />
                              {purchase?.currency?.name}
                           </Table.Cell>
                           <Table.Cell>$ {purchase?.amount}</Table.Cell>
                           <Table.Cell>$ {purchase?.rate}</Table.Cell>

                           <Table.Cell>
                              <StateTag state={purchase?.state} />
                           </Table.Cell>
                        </Table.Row>
                     ))
                  ) : (
                     <Table.Row
                        className="dark:bg-dark-400 "
                        // key={event.id + 'events'}
                     >
                        <Table.Cell
                           colSpan={6}
                           // onClick={() => changeEvent(event)}
                           className="cursor-pointer whitespace-nowrap text-center font-medium text-gray-900 dark:text-red-500"
                        >
                           No Data to Display
                        </Table.Cell>
                     </Table.Row>
                  )}
               </Table.Body>
            </Table>
         </div>
         <SellingModal refresh={refresh} />
      </div>
   )
}
