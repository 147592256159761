import { createContext, useContext, useState } from 'react'
import { useFetch } from '../hooks'
import { useSessionContext } from './SessionContext'

//LINKS
export const apiLink = 'https://api.mudecapital.com'
// export const apiLink = 'http://localhost:4000'
export const assetsLink = apiLink + '/public/assets/images/'
export const eventAssetsLink = apiLink + '/public/assets/images/events/'
export const ApiContext = createContext()

export function ApiProvider({ children }) {
   // const session = getLoggedUser()
   const [users, setUsers] = useState()
   const [purchases, setPurchases] = useState()
   // const [currencies, setCurrencies] = useState()
   const { loggedUserToken, loggedUser } = useSessionContext()
   const [sellings, setSellings] = useState()
   const [swaps, setSwaps] = useState()
   const [user, isLoading, error, refresh] = useFetch(
      '/api/users/data/' + loggedUser?.id,
      loggedUserToken,
   )
   const [currencies, isLoadingCurrencies, errorCurrencies, refreshCurrencies] =
      useFetch('/api/currencies')
   const [
      payment_methods,
      isLoadingPaymentMethods,
      errorPaymentMethods,
      refreshPaymentMethods,
   ] = useFetch('/api/methods')

   const refreshAll = () => {
      refresh()
      refreshPaymentMethods()
      refreshCurrencies()
   }
   return (
      <ApiContext.Provider
         value={{
            user,
            error,
            refresh,
            currencies,
            // assets,
            // errorAssets,
            // refreshAssets,
            // isLoadingAssets,
            errorCurrencies,
            refreshCurrencies,
            payment_methods,
            errorPaymentMethods,
            refreshPaymentMethods,
            isLoading,
            isLoadingPaymentMethods,
            isLoadingCurrencies,

            ///admin stuffs
            users,
            setUsers,
            purchases,
            setPurchases,
            sellings,
            setSellings,
            swaps,
            setSwaps,
            // currencies,
            // setCurrencies,

            //refresh
            refreshAll,
         }}
      >
         {children}
      </ApiContext.Provider>
   )
}

export function useApiContext() {
   const context = useContext(ApiContext)

   if (typeof context === 'undefined') {
      throw new Error(
         'useApiContext should be used within the ApiContext provider!',
      )
   }
   return context
}
