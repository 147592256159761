import { createContext, useContext, useState } from 'react'

export const MethodModalContext = createContext()

export const MethodModalProvider = ({ children }) => {
   const [modalState, setModalState] = useState(false)
   const [create, setCreate] = useState(false)
   const [method, setMethod] = useState()

   const closeModal = () => {
      setModalState(false)
      setCreate()
      setMethod()
   }

   const openCreate = () => {
      setCreate(true)
      setModalState(true)
   }
   const openModal = (curr) => {
      setMethod(curr)
      setModalState(true)
   }

   return (
      <MethodModalContext.Provider
         value={{
            modalState,
            closeModal,
            openModal,
            method,
            setMethod,
            openCreate,
            create,
         }}
      >
         {children}
      </MethodModalContext.Provider>
   )
}

export function useMethodModalContext() {
   const context = useContext(MethodModalContext)

   if (typeof context === 'undefined') {
      throw new Error(
         'useMethodModalContext should be used within the MethodModalProvider!',
      )
   }
   return context
}
