import { Route, Routes } from 'react-router-dom'
import Purchases from './purchases'
import Sellings from './sellings'
import Swaps from './swaps'

export default function Transactions() {
   return (
      <>
         <Routes>
            <Route path="/" element={<Purchases />} />
            <Route path="/sellings" element={<Sellings />} />
            <Route path="/swaps" element={<Swaps />} />
         </Routes>
      </>
   )
}
