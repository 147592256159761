import { Button, TextInput } from 'flowbite-react'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { HiLockClosed, HiMail, HiUserAdd } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import { useApiContext } from '../../utilis/contexts/ApiContext'
import { usePost } from '../../utilis/hooks/index'
import ResetPassword from '../ResetPassword'
import VerifyEmail from '../VerifyEmail'

export default function SignUp({
   handler,
   openPopUp,
   close,
   startSession,
   verify = false,
   admin,
}) {
   const [resetPassword, setResetPassword] = useState(false)
   const [verifyEmail, setVerifyEmail] = useState(verify)
   const [response, error, isLoading, setPost] = usePost(
      admin ? '/auth/admin/authenticate' : '/auth/authenticate',
   )
   // const navigate = useNavigate()
   const { refresh } = useApiContext()
   const {
      handleSubmit,
      formState: { errors },
      reset,
      control,
   } = useForm({
      rules: { required: true },
   })

   const handleClick = (data, handler) => {
      // console.log(data)
      setPost(data)
   }
   const navigate = useNavigate()
   const handleVerify = (email = null) => {
      setResetPassword(false)
      setVerifyEmail(email)
   }

   useEffect(() => {
      if (response?.user) {
         // console.log(response)
         startSession(response.user, response.token)
            .then(() => {
               // Session started successfully
               return refresh() // Assuming refresh returns a Promise
            })
            .catch((error) => {
               // Handle errors during session start or refresh
               console.error('Error starting session or refreshing:', error)
            })
         admin ? navigate('/admin') : navigate('/')
         // openPopUp({
         //    title: 'Authentification Success',
         //    content: 'Bienvenu(e)',
         //    default: {
         //       title: 'Ok',
         //       handler: close,
         //    },
         // })
         reset()
         // reset()
      }
      if (response?.email) {
         // console.log(response)
         // startSession(response.user, response.token)
         // refresh()
         openPopUp({
            title: 'Verification Email Requise',
            content: `Un e-mail contenant votre code de vérification a été envoyé à votre adresse mail ${response?.email}.
                     Si vous ne le trouvez pas dans votre boîte de réception, pensez à vérifier dans vos spams.`,
            default: {
               title: 'Passer à la Verification',
               handler: () => handleVerify(response?.email),
            },
         })
         // reset()
         // reset()
      }
      if (error) {
         openPopUp({
            title: "Erreur d'Authentification",
            content: error.message,
            decline: {
               title: 'Try Again',
            },
         })
      }
   }, [response, error])

   return (
      <div className="flex w-full flex-col items-center">
         <div className=" flex w-full flex-col items-start  justify-start px-4 pb-2 lg:p-4">
            <span className="urbanist text-6 font-bold lg:text-8">
               {resetPassword
                  ? 'Recuperation du Compte'
                  : verifyEmail
                    ? 'Verification Email'
                    : 'Authentification'}
            </span>
            <span className="urbanist">
               {resetPassword
                  ? ''
                  : verifyEmail
                    ? ''
                    : 'Bienvenue ! Veuillez vous connecter pour accéder à votre espace personnel.'}
            </span>
         </div>
         {!resetPassword && !verifyEmail ? (
            <form
               onSubmit={handleSubmit((data) => handleClick(data))}
               className="flex w-full flex-col items-center  justify-center space-y-6 px-4 pb-4"
            >
               {/* <HiUser className="material-icons-round hidden text-24 font-medium text-dark md:block md:text-24 dark:text-bg" /> */}
               <div className="flex w-full flex-col gap-2 sm:gap-3">
                  <Controller
                     control={control}
                     name="email"
                     render={({ field }) => (
                        <TextInput
                           {...field}
                           rightIcon={HiMail}
                           id="username"
                           type="text"
                           minLength={6}
                           placeholder="Entrer votre Email"
                           required
                        />
                     )}
                  />
                  <Controller
                     control={control}
                     name="password"
                     render={({ field }) => (
                        <TextInput
                           {...field}
                           rightIcon={HiLockClosed}
                           minLength={8}
                           id="password"
                           type="password"
                           placeholder="Entrer votre mot de passe"
                           required
                           autoComplete="current-password"
                        />
                     )}
                  />
               </div>
               {!admin && (
                  <div className="justify- flex w-full">
                     <span
                        onClick={() => setResetPassword(true)}
                        className="cursor-pointer text-sm text-primary hover:underline dark:text-gray-400"
                     >
                        Mot de Passe Oublié? récupérer ici.
                     </span>
                  </div>
               )}
               <div className="flex w-full flex-col items-center justify-center gap-1">
                  <Button
                     type="submit"
                     disabled={errors.username || errors.password}
                     isProcessing={isLoading}
                     className="w-full bg-primary dark:bg-secondary"
                  >
                     Connexion
                  </Button>

                  {!admin && (
                     <>
                        <span className=" md:hiden font-semibold text-dark dark:text-darktext">
                           ou
                        </span>

                        <Button
                           onClick={() => handler('register')}
                           color="gray"
                           className="md:hiden flex  w-full items-center border-2 text-primary dark:border-secondary dark:text-darktext"
                        >
                           <HiUserAdd className="mr-2 text-6" />
                           Créer un Compte
                        </Button>
                     </>
                  )}
               </div>
            </form>
         ) : verifyEmail ? (
            <VerifyEmail email={verifyEmail} handler={handleVerify} />
         ) : (
            <ResetPassword
               handler={setResetPassword}
               handleVerify={handleVerify}
            />
         )}
      </div>
   )
}
