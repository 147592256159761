import { Spinner, Table } from 'flowbite-react'
import { useEffect } from 'react'
import {
   ActiveTag,
   AdminTag,
   BtnBack,
   SponsoredTag,
} from '../../../../components/EventEssentiels'
import { SearchBar } from '../../../../components/NavBar'
import { updateDataIfNeeded, useFetch } from '../../../../utilis/hooks'

export default function UsersList({ users, setUsers }) {
   const [localUsers, isLoadingUsers, userError] = useFetch('/api/users')

   const handdleSearch = (e) => {
      const word = e.target.value

      word.length > 3
         ? setUsers(
              localUsers.filter(({ names, email }) => {
                 return (
                    names.toLowerCase().includes(word.toLowerCase()) ||
                    (email && email.toLowerCase().includes(word.toLowerCase()))
                 )
              }),
           )
         : setUsers(localUsers)
   }

   useEffect(() => {
      updateDataIfNeeded(users, localUsers, setUsers)
      // userError && console.log(userError)
   }, [localUsers, userError])
   return (
      <div className="flex max-h-screen w-full flex-col gap-4 py-2 pr-2">
         <div className="justify- z-20 flex flex-row items-center gap-4 rounded bg-darktext-white  p-2 dark:bg-dark-400">
            <BtnBack />
            {/* <BtnCreate /> */}
            <SearchBar
               handdleSearch={handdleSearch}
               title={'un Utilisateur'}
               className="flex-1"
            />
            {/* <Datepicker /> */}
         </div>
         <div className="overflow-x-scroll">
            <Table>
               <Table.Head>
                  {/* <Table.HeadCell>Event Name</Table.HeadCell> */}
                  <Table.HeadCell>Names</Table.HeadCell>
                  <Table.HeadCell>Email</Table.HeadCell>
                  <Table.HeadCell>Telephone</Table.HeadCell>
                  <Table.HeadCell>Verifié</Table.HeadCell>
                  <Table.HeadCell>Role</Table.HeadCell>
                  <Table.HeadCell>State</Table.HeadCell>
                  {/* <Table.HeadCell>Sponsored</Table.HeadCell> */}
               </Table.Head>
               <Table.Body className="divide-y">
                  {users && users.length > 0 ? (
                     users.map((user) => (
                        <Table.Row
                           className=" dark:bg-dark-400 "
                           key={user?.id + 'users'}
                        >
                           <Table.Cell>{user?.names}</Table.Cell>
                           <Table.Cell>{user?.email}</Table.Cell>
                           <Table.Cell>{user?.telephone}</Table.Cell>
                           <Table.Cell>
                              {user?.email_verified_at !== null ? (
                                 <ActiveTag
                                    active="Verifié"
                                    not="Not Active"
                                    state={1}
                                 />
                              ) : (
                                 <ActiveTag
                                    active="Active"
                                    not="No Verifié"
                                    state={0}
                                 />
                              )}
                           </Table.Cell>
                           <Table.Cell>
                              <AdminTag
                                 id={user?.id}
                                 link={'users'}
                                 state={user?.is_admin}
                              />
                           </Table.Cell>
                           <Table.Cell>
                              <SponsoredTag
                                 id={user?.id}
                                 link={'users'}
                                 state={user?.is_active}
                              />
                           </Table.Cell>
                        </Table.Row>
                     ))
                  ) : isLoadingUsers ? (
                     <Spinner />
                  ) : (
                     <Table.Row
                        className="dark:bg-dark-400 "
                        // key={event.id + 'events'}
                     >
                        <Table.Cell
                           colSpan={6}
                           // onClick={() => changeEvent(event)}
                           className="cursor-pointer whitespace-nowrap text-center font-medium text-gray-900 dark:text-red-500"
                        >
                           No Data to Display
                        </Table.Cell>
                     </Table.Row>
                  )}
               </Table.Body>
            </Table>
         </div>
      </div>
   )
}
