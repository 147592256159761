import { useEffect } from 'react'
import { useApiContext } from '../../../utilis/contexts/ApiContext'
import { useNavContext } from '../../../utilis/contexts/NavContext'
import UsersList from './userlist'

export default function Users() {
   const { handler } = useNavContext()

   useEffect(() => {
      handler('Utilisateurs')
   })
   const { users, setUsers } = useApiContext()
   return <UsersList users={users} setUsers={setUsers} />
}
