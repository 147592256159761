import { Table } from 'flowbite-react'
import { useEffect } from 'react'
import { CgArrowsExchangeAlt } from 'react-icons/cg'
import CurrencyLogo from '../../../../components/CurrencyLogo/CurrencyLogo'
import {
   BtnBack,
   BtnRefresh,
   StateTag,
} from '../../../../components/EventEssentiels'
import { SearchBar } from '../../../../components/NavBar'
import { useApiContext } from '../../../../utilis/contexts/ApiContext'
import { useNavContext } from '../../../../utilis/contexts/NavContext'
import { useSwapContext } from '../../../../utilis/contexts/SwapContext'
import { updateDataIfNeeded, useFetch } from '../../../../utilis/hooks'
import { TheSpiner } from '../purchases'
import SwapModal from './SwapModal'

export default function Swaps() {
   const [localUsers, isLoadingUsers, userError, refresh] =
      useFetch('/api/swaps')
   const { swaps, setSwaps } = useApiContext()
   const { handler } = useNavContext()
   const { openShowModal } = useSwapContext()

   const handdleSearch = (e) => {
      const word = e.target.value

      word.length > 3
         ? setSwaps(
              localUsers.filter(({ reference, currency }) => {
                 return (
                    reference.toLowerCase().includes(word.toLowerCase()) ||
                    (currency &&
                       currency?.name
                          ?.toLowerCase()
                          .includes(word.toLowerCase()))
                 )
              }),
           )
         : setSwaps(localUsers)
   }

   useEffect(() => {
      handler('Echanges')
   })
   useEffect(() => {
      updateDataIfNeeded(swaps, localUsers, setSwaps)
      // userError && console.log(userError)
   }, [localUsers, userError])
   return (
      <div className="flex max-h-screen w-full flex-col gap-4 py-2 pr-2">
         <div className="justify flex flex-row items-center gap-4 rounded bg-darktext-white  p-2 dark:bg-dark-400">
            <BtnBack />
            <BtnRefresh onClick={() => refresh()} />
            <span className="p-2 font-semibold lg:text-6">Echanges</span>
            <SearchBar
               title={'un Echange'}
               handdleSearch={handdleSearch}
               className="flex-1"
            />
            {/* <Datepicker /> */}
         </div>
         <div className="overflow-x-scroll">
            <Table>
               <Table.Head>
                  {/* <Table.HeadCell>Event Name</Table.HeadCell> */}
                  <Table.HeadCell>N° Reference</Table.HeadCell>
                  <Table.HeadCell>Devise</Table.HeadCell>
                  {/* <Table.HeadCell>Montant</Table.HeadCell> */}
                  <Table.HeadCell>Frais</Table.HeadCell>
                  <Table.HeadCell>Total</Table.HeadCell>

                  <Table.HeadCell>State</Table.HeadCell>
                  {/* <Table.HeadCell>Sponsored</Table.HeadCell> */}
               </Table.Head>
               <Table.Body className="divide-y">
                  {isLoadingUsers && <TheSpiner />}
                  {swaps && swaps.length > 0 ? (
                     swaps.map((purchase) => (
                        <Table.Row
                           className=" border-b p-2 dark:border-secondary-500 dark:bg-dark-400"
                           key={purchase?.id + 'users'}
                        >
                           <Table.Cell
                              onClick={() => {
                                 openShowModal(purchase)
                              }}
                              className=" cursor-pointer  hover:bg-darktext-white hover:font-bold dark:hover:bg-dark"
                           >
                              {purchase?.reference}
                           </Table.Cell>
                           <Table.Cell
                              onClick={() => {
                                 openShowModal(purchase)
                              }}
                              className="flex cursor-pointer items-center gap-2 hover:bg-darktext-white  hover:font-bold  dark:hover:bg-dark"
                              // className=""
                           >
                              <div className="flex items-center gap-1 text-start">
                                 <CurrencyLogo img={purchase?.from?.cover} />
                                 {purchase?.amount}
                              </div>
                              <CgArrowsExchangeAlt className="text-6" />
                              <div className="flex items-center gap-1 text-start">
                                 {/* <CurrencyLogo img={purchase?.from?.cover} /> */}
                                 <CurrencyLogo img={purchase?.to?.cover} />
                                 {(purchase?.amount * purchase?.price).toFixed(
                                    2,
                                 )}
                              </div>
                              {/* {purchase?.currency?.name} */}
                           </Table.Cell>
                           {/* <Table.Cell>$ {purchase?.amount}</Table.Cell> */}
                           <Table.Cell>
                              <div className="flex items-center gap-1 text-start">
                                 {/* <CurrencyLogo img={purchase?.from?.cover} /> */}
                                 <CurrencyLogo img={purchase?.to?.cover} />
                                 {purchase?.rate}
                              </div>
                           </Table.Cell>
                           <Table.Cell>
                              <div className="flex items-center gap-1 text-start">
                                 <CurrencyLogo img={purchase?.to?.cover} />
                                 {(
                                    purchase?.amount * purchase?.price -
                                    purchase?.rate
                                 ).toFixed(2)}
                              </div>
                           </Table.Cell>

                           <Table.Cell>
                              <StateTag state={purchase?.state} />
                           </Table.Cell>
                        </Table.Row>
                     ))
                  ) : (
                     <Table.Row
                        className="dark:bg-dark-400 "
                        // key={event.id + 'events'}
                     >
                        <Table.Cell
                           colSpan={6}
                           // onClick={() => changeEvent(event)}
                           className="cursor-pointer whitespace-nowrap text-center font-medium text-gray-900 dark:text-red-500"
                        >
                           No Data to Display
                        </Table.Cell>
                     </Table.Row>
                  )}
               </Table.Body>
            </Table>
         </div>
         <SwapModal refresh={refresh} />
      </div>
   )
}
